export function AdHocEmails(resource_type, resource_id) {
  this.resource_type = resource_type;
  this.resource_id = resource_id;
  this.data = [];
  this.load();
}

AdHocEmails.prototype.validEmail = function (email) {
  const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return pattern.test(email);
}

AdHocEmails.prototype.makeEmailEntry = function (id, email) {
  return "<div><a style='color: red' id='" + this.makeRemoveId(id) + "'  href='javascript: return false'>remove</a>&nbsp;&nbsp;&nbsp;" + email + "</div>";
}

AdHocEmails.prototype.displayEmailList = function (html) {
  $("#ad_hoc_emails").html(html);
}

AdHocEmails.prototype.emailInputObject = function () {
  return $("#add_cc_contact_email");
}

AdHocEmails.prototype.emailAddButtonObject = function () {
  return $("#add_contact_button");
}

AdHocEmails.prototype.attachAddButton = function () {
  const _this = this;
  this.emailAddButtonObject().unbind();
  this.emailAddButtonObject().click(function () {
    _this.addEmail(_this.emailInputObject().val());
    _this.emailInputObject().val('');
  });
}

AdHocEmails.prototype.loadEmails = function () {
  const size = this.data.length;
  const htmlEntries = [];
  for (let i = 0; i < size; i++) {
    const id = this.data[i][0];
    const email = this.data[i][1];
    htmlEntries.push(this.makeEmailEntry(id, email));
  }
  this.displayEmailList(htmlEntries.join(''));
}

AdHocEmails.prototype.resourceUrl = function () {
  return "/ad_hoc_emails/" + this.resource_type + "/" + this.resource_id
}

AdHocEmails.prototype.deleteUrl = function (id) {
  return this.resourceUrl() + "/" + id;
}

AdHocEmails.prototype.getAllUrl = function () {
  return this.resourceUrl();
}

AdHocEmails.prototype.addUrl = function () {
  return this.resourceUrl();
}

AdHocEmails.prototype.removeEmail = function (id) {
  const _this = this;
  const url = this.deleteUrl(id);
  $.ajax({
    type: "DELETE",
    url: url,
    success: function (data, status, xhr) {
      _this.data = data;
      _this.load(data);
    },
    error: function (xhr, textStatus, errorThrown) {
    },
    dataType: "json"
  });
}

AdHocEmails.prototype.makeRemoveId = function (id) {
  return "email_" + id;
}

AdHocEmails.prototype.attachRemoveEvents = function () {
  const _this = this;
  const size = this.data.length;
  for (let i = 0; i < size; i++) {
    const emailId = this.data[i][0];
    const emailKey = this.makeRemoveId(emailId);
    (function (emailId) {
      $("#" + emailKey).click(function () {
        _this.removeEmail(emailId);
      });
    })(emailId);
  }
}

AdHocEmails.prototype.addEmail = function (email) {
  if (this.validEmail(email)) {
    const _this = this;
    $.post(this.addUrl(), {email: email}, function (data) {
      _this.data = data;
      _this.load(data);
    });
  } else {
    alert("Email is invalid.");
  }
}

AdHocEmails.prototype.load = function (data) {
  if (typeof (data) == 'undefined') {
    const _this = this;
    $.get(this.getAllUrl(), function (data) {
      _this.data = data;
      _this.loadEmails();
      _this.attachAddButton();
      _this.attachRemoveEvents();
    });
  } else {
    this.loadEmails();
    this.attachAddButton();
    this.attachRemoveEvents();
  }
}



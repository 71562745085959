export function ContactForm(
    create_user_url,
    contact_role_id,
    company_id,
    cc_type_factory
) {
  this.create_user_url = create_user_url;
  this.contact_role_id = contact_role_id;
  this.company_id = company_id;
  this.cc_type_factory = cc_type_factory;
}

ContactForm.prototype = {
  contact_errors_display: function () {
    return $("#contact-errors");
  },
  source_contact_errors_display: function () {
    return $("#source-contact-errors");
  },
  contact_name_field: function () {
    return $("#contact-name");
  },
  contact_email_field: function () {
    return $("#contact-email");
  },
  source_contact_name_field: function () {
    return $("#source-contact-name");
  },
  source_contact_email_field: function () {
    return $("#source-contact-email");
  },
  contact_type_field: function () {
    return $("#contact-type");
  },
  company_id_field: function () {
    return $("#company-id");
  },
  role_id_field: function () {
    return $("#role-id");
  },
  valid_email: function (email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  format_errors: function (errors) {
    let entry;
    const size = errors.length;
    for (let i = 0; i < size; i++) {
      entry = errors[i];
      errors[i] = entry[0].toUpperCase() + entry.slice(1);
      console.log(entry[0].toUpperCase() + entry.slice(1));
    }
    return errors.join("</br>");
  },
  clear_errors: function () {
    this.contact_errors_display().hide();
    this.contact_errors_display().html("");
  },
  display_errors: function (errors) {
    this.contact_errors_display().html(this.format_errors(errors));
    this.contact_errors_display().show();
  },
  display_source_errors: function (errors) {
    this.source_contact_errors_display().html(this.format_errors(errors));
    this.source_contact_errors_display().show();
  },
  contact_submit: function () {
    const name = this.contact_name_field().val();
    const email = this.contact_email_field().val();
    const contact_type = this.contact_type_field().val();

    const errors = [];
    if (name.length == 0)
      errors.push("Please enter a name for the contact.");

    if (!this.valid_email(email))
      errors.push("Please enter a valid email for the contact.");

    if (errors.length > 0) {
      this.display_errors(errors);
    } else {
      const post_data = {
        user: {
          email: email,
          name: name,
          phone: "",
          role_id: this.contact_role_id,
          company_id: this.company_id,
          active: true
        }
      };

      // XXX rename cc_type_factory to allow for single object.
      console.log(this.create_user_url);
      let contact_method = null;
      let other_contact_method = null;
      if (typeof this.cc_type_factory == "function") {
        const methods = this.cc_type_factory(this.contact_type_field().val());
        contact_method = methods[0];
        other_contact_method = methods[1];
      } else {
        contact_method = this.cc_type_factory;
      }

      const _this = this;
      $.ajax({
        type: "POST",
        url: _this.create_user_url,
        data: post_data,
        success: function (data, status, xhr) {
          contact_method.add_user(data.id);
          // need to populate the other drop down of users with the new cc user.
          if (other_contact_method) other_contact_method.populate();
          $.modal.close();
        },
        error: function (xhr, status, error) {
          if (xhr.responseText) {
            const response_errors = new Function("(" + xhr.responseText + ")");
            let errors = [];
            for (const key in response_errors) {
              errors.push(key + " " + response_errors[key]);
            }
            _this.display_errors(errors);
          }
        },
        dataType: "json"
      });
    }
  },
  source_contact_submit: function () {
    //debugger;
    const name = this.source_contact_name_field().val();
    const email = this.source_contact_email_field().val();
    const companyId = this.company_id_field().val();
    const roleId = this.role_id_field().val();

    let errors = [];

    //heavily ripped off of create select, need it on a dropdown that isnt a class method
    const create_contact_select = function (option_values, id, oldValue) {
      const _this = $("#" + id);
      _this.empty();
      console.log(_this)
      const size = option_values.length;
      let entry = null;
      for (let i = 0; i < size; i++) {
        entry = option_values[i];
        _this.append(
            $("<option></option>")
                .val(entry.value)
                .html(entry.label)
        );
      }
      _this.val(oldValue).trigger("chosen:updated");
    };
    //heavily ripped off of make users into options, need it on a dropdown that isnt a class method
    const make_contacts_into_options = function (data) {
      const options = [];
      const size = data.length;
      let user = null;
      for (let i = 0; i < size; i++) {
        user = data[i];
        options.push({value: user[1], label: user[0]});
      }
      return options;
    };

    const rebuildList = function () {
      $.get("/contacts/contacts_for_select.json", function (data) {
        const selectedSampleContactId = $('#source_sample_contact_id').val();
        const selectedBillingContactId = $('#source_billing_contact_id').val();
        const options = make_contacts_into_options(data);
        create_contact_select(options, "source_sample_contact_id", selectedSampleContactId)
        create_contact_select(options, "source_billing_contact_id", selectedBillingContactId)
      })
    };

    if (name.length === 0)
      errors.push("Please enter a name for the contact.");

    if (!this.valid_email(email))
      errors.push("Please enter a valid email for the contact.");

    if (errors.length > 0) {
      this.display_source_errors(errors);
    } else {
      const post_data = {
        user: {
          email: email,
          name: name,
          phone: "",
          role_id: roleId,
          company_id: companyId,
          active: true
        }
      };


      // XXX rename cc_type_factory to allow for single object.
      console.log(this.create_user_url);
      //todo: point to new endpoint
      const _this = this;
      $.ajax({
        type: "POST",
        url: _this.create_user_url,
        data: post_data,
        success: function (data, status, xhr) {
          rebuildList();
          $.modal.close();
        },
        error: function (xhr, status, error) {
          if (xhr.responseText) {
            const response_errors = new Function("(" + xhr.responseText + ")");
            const errors = [];
            for (const key in response_errors) {
              errors.push(key + " " + response_errors[key]);
            }
            _this.display_source_errors(errors);
          }
        },
        dataType: "json"
      });
    }
  }
};

import {CCUsers, ContactForm} from '../classes'

export const sources = {
  init: function initSources(container) {
    const $container = $(container || 'body');

    const values = this.getIds($container);
    const isPersisted = $container.find('#source_persisted').val() === 'true';
    let contactForm, tables;

    tables = this.prepareUserLists($container, values);
    contactForm = this.prepareContactForm(values, tables);

    $(document).on($.modal.BEFORE_OPEN, function (event, modal) {
      const type = modal.$anchor.data('ccUser');
      const company_id = modal.$anchor.data('companyId');
      const role_id = modal.$anchor.data('roleId');
      const $contactType = $container.find('#contact-type');
      const $companyId = $container.find('#company-id');
      const $roleId = $container.find('#role-id');
      if (type) {
        $contactType.val(type);
      }
      if (company_id) {
        $companyId.val(company_id);
      }
      if (role_id) {
        $roleId.val(role_id);
      }
    });

    $container.find('#Btn_CreateContact').click(function () {
      contactForm.contact_submit();
    });
    $container.find('#Btn_CreateSourceContact').click(function () {
      contactForm.source_contact_submit();
    });

    $container.find('#Btn_CancelCreateContact').click(function (event) {
      event.preventDefault();
      $.modal.close();
    });
  },
  prepareUserLists: function ($container, values) {
    const billingUrls = this.getUrls('billing', values.sourceId);
    const sampleUrls = this.getUrls('sample', values.sourceId);
    let sampleCCUsers, billingCCUsers;

    if ($container.find('#sample_cc_users').length > 0) {
      sampleCCUsers = new CCUsers('sample_cc_users', sampleUrls);
      sampleCCUsers.populate();
    }

    if ($container.find('#billing_cc_users').length > 0) {
      billingCCUsers = new CCUsers('billing_cc_users', billingUrls);
      billingCCUsers.populate();
    }

    return {
      sampleCCUsers: sampleCCUsers,
      billingCCUsers: billingCCUsers
    };
  },
  prepareContactForm: function prepareSourceContactForm(values, userLists) {
    const createUserUrl = `/companies/${values.companyId}/users.json`;

    return new ContactForm(createUserUrl, values.contactRoleId, values.companyId, ccTypeFactory);

    function ccTypeFactory(ccType) {
      const methods = [
        ['sample', userLists.sampleCCUsers],
        ['billing', userLists.billingCCUsers]
      ];

      let contactMethod, otherContactMethod, name, klass;

      for (let i = 0; i < 2; i++) {
        const entry = methods[i];

        name = entry[0];
        klass = entry[1];

        if (name === ccType) {
          contactMethod = klass;
        } else {
          otherContactMethod = klass;
        }
      }

      return [contactMethod, otherContactMethod];
    }
  },
  getIds: function getSourceValues($container) {
    return {
      sourceId: $container.find('#source_id').val(),
      companyId: $container.find('#source_company_id').val(),
      contactRoleId: $container.find('#contact_role_id').val()
    };
  },
  getUrls: function getSourceUrls(type, id) {
    return {
      ccUsers: `/sources/${id}/source_users/${type}_users`,
      availableUsers: `/sources/${id}/source_users/available_${type}_users`,
      addUser: `/sources/${id}/source_users/add_${type}_user`,
      removeUser: `/sources/${id}/source_users/remove_${type}_user/`
    };
  }
};

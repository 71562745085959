import $ from 'jquery';
import Handlebars from 'handlebars';

export const fileFields = (function () {
  let $fileFieldsWrapper;
  let fileFieldTemplate;

  const methods = {
    addFileField: function methodsAddFileField() {
      $fileFieldsWrapper.append(
        fileFieldTemplate({
          classes: 'push-half--top'
        })
      );
    },
    removeFileField: function methodsRemoveFileField(source) {
      $(source).parent().remove();
    }
  };

  const handlers = {
    clickAction: function handleClickAction(event) {
      const action = $(event.currentTarget).data('fileFieldsAction');

      switch (action) {
        case 'add':
          methods.addFileField();
          break;
        case 'remove':
          methods.removeFileField(event.currentTarget);
          break;
        default:
          break;
      }
    }
  };

  function initEvents() {
    $(document).on('click', '[data-file-fields-action]', handlers.clickAction);
  }

  function init() {
    let fileFieldHTML;
    $fileFieldsWrapper = $('#FileFields_Wrapper');

    if ($fileFieldsWrapper.length) {
      fileFieldHTML = $('#file-field-template').html();

      if (fileFieldHTML.length) {
        fileFieldTemplate = Handlebars.compile(fileFieldHTML);
      }

      initEvents();
    }
  }

  return {
    init: init
  };
})();

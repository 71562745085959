import {SWITCHER_TARGET_PREFIX, SWITCHER_BUTTON_PREFIX} from '@constants';
import {getAllAfterIndex, getAllBeforeIndex, getHashContent, setHash} from '@utils';

export const switcher = {
  methods: {
    highlightSwitcherButton: function methodsHighlightSwitcherButton(target) {
      $(".switcher button").removeClass("is-active");
      $(target).addClass("is-active");
    },
    switchSection: function methodsSwitchSection(current, target) {
      const $toShow = $(`#${SWITCHER_TARGET_PREFIX}${target}`);
      const height = $(`#${SWITCHER_TARGET_PREFIX}${current}`).innerHeight();
      $(`#${SWITCHER_TARGET_PREFIX}${current}`).parent(".flex-wrap-box").height(height);
      $(".switcher-target").addClass("is-hidden");

      setTimeout(function () {
        $toShow.removeClass("is-hidden");
        $(`#${SWITCHER_TARGET_PREFIX}${current}`)
            .parent()
            .removeAttr("style");
      }, 100);

      window.location.hash = target;
    }
  },
  handlers: {
    clickSwitcherButton: function handleClickSwitcherButton(event) {
      const target = getAllAfterIndex(event.currentTarget.id, "_");
      const current = getAllAfterIndex(
          $(".switcher-target:not(.is-hidden)").attr("id"),
          "_"
      );

      event.preventDefault();

      if ($(this).hasClass("is-active")) {
        return false;
      }

      $(".switcher button").removeClass("is-active");
      $("#" + event.currentTarget.id).addClass("is-active");

      this.methods.switchSection(current, target);
    }
  },
  init: function initSwitcher() {
    const hash = getHashContent();
    const $firstTarget = $(".switcher-target").first();
    let buttonTarget = " ";
    let switcherSection;

    if ($(".switcher-target").length !== 0) {
      if (hash) {
        switcherSection = getAllBeforeIndex(hash, "-");
        if (!switcherSection) {
          switcherSection = hash;
        }
      } else if (!hash) {
        switcherSection = getAllAfterIndex(
            $(".switcher-target:first-of-type").attr("id"),
            "_"
        );
      }

      $(".switcher-target").addClass("is-hidden");

      $(`#${SWITCHER_TARGET_PREFIX}${switcherSection}`).removeClass("is-hidden");

      buttonTarget = `#${SWITCHER_BUTTON_PREFIX}${switcherSection}`;
      this.methods.highlightSwitcherButton(buttonTarget);

      setHash(switcherSection);

      $(document).on(
          "click",
          ".switcher button",
          this.handlers.clickSwitcherButton.bind(this)
      );
    }
  }
};

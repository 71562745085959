import $ from 'jquery';
import {chosenObjectsWithSearch} from '@constants';

const chosenObjectIds = chosenObjectsWithSearch.map((obj) => obj.id.replace('#', ''));

export const chosen = {
  init: function initChosen(container) {
    container = container || 'body';

    $(container)
      .find('select')
      .each(function (index, element) {
        const options = {
          width: '100%',
          disable_search_threshold: chosenObjectIds.includes(element.id) ? 4 : undefined
        };

        $(element).chosen(options);
      });
  },
  destroy: function destroyChosen(container) {
    container = container || 'body';

    $(container)
      .find('select')
      .each(function (index, element) {
        // TODO: for some reason .chosen('destroy') is not working, so we're doing it manually for now
        $(element).show();
        $(element).next('.chosen-container').remove();
      });
  }
};

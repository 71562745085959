import $ from 'jquery';

export const foldup = {
  init: function initFoldup() {
    // HACK:
    // this handler is sometimes called more than once during
    // a view. which causes this handler to get attached
    // multiple times. I considered refactoring it to avoid
    // this quick solution, but ultimately this is the smarter path,
    // time and budget considering.
    $(document)
      .off('click', '.foldup-header')
      .on('click', '.foldup-header', function () {
        const $this = $(this);
        const $foldup = $this.parent('.foldup');

        $foldup.addClass('is-animated').toggleClass('is-open', !$foldup.hasClass('is-open'));

        if ($foldup.hasClass('foldup-modal')) {
          $foldup.siblings().addClass('is-animated').removeClass('is-open');

          setTimeout(function () {
            $foldup.siblings().removeClass('is-animated');
          });
        }

        setTimeout(function () {
          $foldup.removeClass('is-animated');
        }, 300);
      });
  }
};

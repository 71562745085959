import $ from 'jquery';
import Sortable from 'sortablejs';
import { createQueryString, requestTemplate, supplant } from '@utils';

let url;
let availableSortable;
let currentSortable;
let context;
let $container;

const elements = {};
const elementNames = [
  'availableSamples',
  'availableSamplesHeader',
  'context',
  'controls',
  'currentSamples',
  'currentSamplesHeader',
  'form',
  'formInner',
  'formSubmit',
  'formWrapper',
  'message',
  'newInvoicePath',
  'persisted',
  'samples',
  'samplesSearchPath',
  'selectCompany',
  'selectSamples',
  'selectSource',
  'sourcesPath',
  'unavailable',
  'unavailableSamples',
  'unavailableSamplesHeader',
  'wrapper'
];

const sampleSelector = '[data-sample-id]';

const messages = {
  selectCompanyAndSource: 'Please select a company to begin.',
  selectSource: 'Select a source for {company}.',
  loading: 'Loading available samples…'
};

const containers = {
  allSamples: 'allSamples',
  availableSamples: 'availableSamples',
  unavailableSamples: 'unavailableSamples',
  currentSamples: 'currentSamples'
};

const sampleFragments = {
  allSamples: document.createDocumentFragment(),
  availableSamples: document.createDocumentFragment(),
  unavailableSamples: document.createDocumentFragment(),
  currentSamples: document.createDocumentFragment()
};

const utils = {
  fragments: {
    empty: function utils_fragmentsEmpty(name) {
      const fragment = sampleFragments[name];

      while (fragment.firstChild) {
        fragment.removeChild(fragment.firstChild);
      }
    },
    set: function utils_fragmentsSet(name) {
      const fragment = sampleFragments[name];

      elements[name].empty();
      elements[name][0].appendChild(fragment);
    },
    sort: function utils_fragmentsSort(a, b) {
      const aId = $(a).data('sampleId');
      const bId = $(b).data('sampleId');

      if (aId > bId) return -1;
      if (aId < bId) return 1;
      return 0;
    }
  },
  get: {
    wrapperCss: function utils_getWrapperCss(canOverflow, padding) {
      padding = padding || 0;

      if (canOverflow)
        return {
          overflow: 'auto',
          paddingRight: padding / 2,
          marginBottom: padding / 2
        };

      return {
        overflow: 'hidden',
        marginBottom: 0
      };
    }
  },
  make: {
    hiddenField: function utils_makeHiddenField(name, value) {
      const input = document.createElement('input');

      input.setAttribute('name', name);
      input.setAttribute('value', value);
      input.setAttribute('type', 'hidden');
      input.setAttribute('data-hidden-field-for', name);

      return input;
    },
    option: function utils_makeOption(label, value, isSelected) {
      let option;
      let attributes = {
        value: value
      };

      if (isSelected) {
        attributes.selected = '';
      }

      option =
        'option ' +
        Object.keys(attributes).reduce(function (final, key) {
          return `${final} ${key}${attributes[key] === '' ? '' : '="' + attributes[key] + '"'}`;
        }, '');

      return `<${option}>${label}</option>`;
    }
  },
  set: {
    element: function utils_setElement($container, name) {
      elements[name] = $container.find('#Invoices_' + name.charAt(0).toUpperCase() + name.slice(1));
    },
    islandStyle: function setIslandStyle(element) {
      var commonCss = {
        overflow: 'hidden',
        marginBottom: 0
      };

      element.find('.island').first().addClass('island-fullheight').css(commonCss);
    },
    message: function utils_setMessage(type, params) {
      let message;

      params = params || {};
      message = supplant(messages[type], params);

      elements.message.html(message);

      utils.toggle.context('message');
      utils.clearFormWrapper();
    }
  },
  toggle: {
    context: function utils_toggleContext(context) {
      elements.message.toggleClass('is-hidden', context !== 'message');
      elements.formWrapper.toggleClass('is-hidden', context !== 'form');
    },
    dropdowns: function utils_toggleDropdowns(isEnabled) {
      elements.wrapper.find('[data-hidden-field-for]').remove();

      elements.selectCompany.attr('disabled', !isEnabled).trigger('chosen:updated');
      elements.selectSource.attr('disabled', !isEnabled).trigger('chosen:updated');

      window.requestAnimationFrame(function () {
        if (!isEnabled) {
          elements.selectCompany.after(
            utils.make.hiddenField(
              elements.selectCompany.attr('name'),
              elements.selectCompany.val()
            )
          );

          elements.selectSource.after(
            utils.make.hiddenField(elements.selectSource.attr('name'), elements.selectSource.val())
          );
        }
      });
    }
  },
  clearFormWrapper: function utils_clearFormWrapper() {
    window.requestAnimationFrame(function () {
      elements.formWrapper.empty();
    });
  },
  getCompanyName: function utils_getCompanyName() {
    return elements.selectCompany.find(':selected').text();
  },
  isDashboard: function utils_isDashboard() {
    return context === 'dashboard';
  }
};

var methods = {
  cacheAllSamples: function methodCacheAllSamples() {
    utils.fragments.empty('allSamples');
    elements.availableSamples.find(sampleSelector).each(function (index, sample) {
      sampleFragments.allSamples.appendChild(sample.cloneNode(true));
    });
  },
  dashboardForm: {
    compute: {
      total: function methodsDashboardFormComputeTotal(styles, props) {
        return props.reduce(function (final, prop) {
          return final + parseInt(styles[prop], 10);
        }, 0);
      },
      heights: function methodsDashboardFormComputeHeights(styles) {
        const selectSamplesTitleStyles = window.getComputedStyle(
          elements.selectSamples.find('.island-title').first()[0]
        );

        const BASE_TOP = elements.wrapper[0].getBoundingClientRect().top;
        const BASE_PADDING = parseInt(styles.form.padding, 10);

        // the height of the overall wrapper.
        const wrapperHeight =
          window.innerHeight - ((Number.isNaN(BASE_TOP) ? 0 : BASE_TOP) + BASE_PADDING);

        // the height of the form wrapper.
        const formWrapperHeight =
          wrapperHeight - this.total(styles.controls, ['height', 'marginBottom']);

        // the height of the inner form contents (which excludes the submit bar)
        const formInnerHeight =
          formWrapperHeight -
          (this.total(styles.formSubmit, ['height', 'paddingTop', 'marginTop']) + BASE_PADDING);

        // the height for the outer wrapper around the Select Sample form partial.
        // must match the height of the form inner contents so overflow
        // works correctly
        const selectSamplesHeight = formInnerHeight;

        // The height of the wrappers immediately around the sample containers.
        const samplesHeight =
          formInnerHeight -
          (this.total(selectSamplesTitleStyles, ['height', 'marginBottom']) + BASE_PADDING * 2);

        const percentageOfSamplesHeight = function (percentage) {
          return (
            samplesHeight * percentage -
            (BASE_PADDING + this.total(styles.availableSamplesHeader, ['height', 'marginBottom']))
          );
        }.bind(this);

        const availableSamplesHeight = percentageOfSamplesHeight(utils.isDashboard() ? 0.6666 : 1);
        const unavailableSamplesHeight = percentageOfSamplesHeight(
          utils.isDashboard() ? 0.3333 : 0
        );
        const currentSamplesHeight = percentageOfSamplesHeight(1);

        return {
          basePadding: BASE_PADDING,
          wrapperHeight: wrapperHeight,
          formWrapperHeight: formWrapperHeight,
          formInnerHeight: formInnerHeight,
          selectSamplesHeight: selectSamplesHeight,
          samplesHeight: samplesHeight,
          availableSamplesHeight: availableSamplesHeight,
          unavailableSamplesHeight: unavailableSamplesHeight,
          currentSamplesHeight: currentSamplesHeight
        };
      },
      styles: function methodsDashboardFormComputStyles(names) {
        return names.reduce(function (styles, name) {
          if (elements[name] && elements[name].length) {
            styles[name] = window.getComputedStyle(elements[name][0]);
          }

          return styles;
        }, {});
      }
    },
    setHeights: function methodsDashboardFormSetHeights() {
      const useSmallMode = window.innerHeight < 825;

      const styles = this.compute.styles(elementNames);
      const heights = this.compute.heights(styles);

      if (useSmallMode === false) {
        Object.keys(heights).forEach(function (heightName) {
          const name = heightName.replace('Height', '');

          if (elements[name] && elements[name].length) {
            elements[name].height(heights[heightName]).css(utils.get.wrapperCss(false));
          }
        });
      }

      [elements.formWrapper, elements.selectSamples, elements.samples].forEach(
        utils.set.islandStyle
      );

      [elements.availableSamples, elements.unavailableSamples, elements.currentSamples].forEach(
        function ($element) {
          if ($element.length) {
            $element.css(utils.get.wrapperCss(true, heights.basePadding));
            $element.css('margin-bottom', heights.basePadding / 2);

            $element.css('min-height', useSmallMode ? '25vh' : '0');
            $element.css('max-height', useSmallMode ? '40vh' : 'none');
          }
        }
      );
    },
    init: function methodsDashboardFormInit() {
      this.setHeights();

      $(window).on('resize', this.setHeights.bind(this));
    }
  },
  samples: {
    checkAvailability: function methodsSamplesCheckAvailability(params, props, sample) {
      return props.reduce(function (finalStatus, name) {
        if (finalStatus === false) return false;

        if (params[name]) {
          finalStatus = params[name].toString() === (sample.dataset[name] || '').toString();
        }

        return finalStatus;
      }, true);
    },
    hideSubtitle: function methodsSampleHideSubtitle(sample, hideAll) {
      const $title = $(sample).find('.foldup-title');
      const $subtitle = $(sample).find('.foldup-subtitle');

      $title.css('margin-bottom', 0);

      window.requestAnimationFrame(function () {
        if (elements.selectCompany.val() !== '' && !hideAll) {
          $subtitle.find('strong').hide();
        } else {
          $subtitle.hide();
        }
      });
    },
    assignToList: function methodsSamplesAssignToList(sample, isAvailable, isHidden) {
      const target = isAvailable ? 'availableSamples' : 'unavailableSamples';
      const targetFragment = sampleFragments[target];
      let sortedSamples;

      if (targetFragment) {
        if ((isAvailable && !isHidden) || !isAvailable) {
          targetFragment.appendChild(sample.cloneNode(true));
          sortedSamples = $(targetFragment).find(sampleSelector).get().sort(utils.fragments.sort);

          utils.fragments.empty(target);
          sortedSamples.forEach(function (sample) {
            targetFragment.appendChild(sample);
          });
        }
      }
    },
    toggleAvailable: function methodsSamplesToggleAvailable(params, sampleIds) {
      const allSamples = $(sampleFragments.allSamples).find(sampleSelector);
      const idNames = ['companyId', 'sourceId'];
      const fragmentNames = [containers.availableSamples, containers.unavailableSamples];
      let sampleIndex;

      fragmentNames.forEach(utils.fragments.empty);

      for (sampleIndex = 0; sampleIndex < allSamples.length; sampleIndex++) {
        const sample = allSamples[sampleIndex];

        const sampleId = $(sample).data('sampleId');
        const isAvailable = this.checkAvailability(params, idNames, sample);
        const isHidden = (sampleIds || []).indexOf(sampleId.toString()) !== -1;

        this.assignToList(sample, isAvailable, isHidden);
      }

      fragmentNames.forEach(utils.fragments.set);
      methods.setElements();

      const shouldHideSubtitles = (function () {
        if (elements.currentSamples.children().length > 0) return true;

        return elements.selectCompany.val() !== '' && elements.selectSource.val() !== '';
      })();

      if (shouldHideSubtitles) {
        elements.availableSamples.find(sampleSelector).each(
          function (index, sample) {
            this.hideSubtitle(sample);
          }.bind(this)
        );
      }

      elements.currentSamples.find(sampleSelector).each(
        function (index, sample) {
          this.hideSubtitle(sample, true);
        }.bind(this)
      );

      elements.unavailableSamples.find(sampleSelector).each(function (index, element) {
        const $element = $(element);

        $element.css({
          opacity: 0.375
        });

        $element.find('.foldup-header').css({
          pointer: 'not-allowed',
          pointerEvents: 'none'
        });
      });
    }
  },
  requestNewForm: function methodRequestNewForm(params) {
    params = createQueryString(params || {});
    utils.toggle.dropdowns(false);

    if (url) {
      utils.set.message('loading');
      requestTemplate(url + '?' + params, this.setNewForm.bind(this), '#Invoices_Form');
    }
  },
  setDropdownValues: function methodSetDropdownValues(company, source) {
    if (!company || !source) return;

    if (elements.selectSource.find('option').length === 1) {
      elements.selectSource.html(utils.make.option(source.name, source.id, true));
    }

    window.requestAnimationFrame(function () {
      elements.selectCompany.val(company.id).trigger('chosen:updated');
      elements.selectSource.val(source.id).trigger('chosen:updated');
    });
  },
  setElements: function methodSetElements() {
    elementNames.forEach(utils.set.element.bind(undefined, $container));
  },
  setNewForm: function methodSetNewForm(form) {
    elements.formWrapper.html(form);
    methods.setElements();

    if (utils.isDashboard()) {
      methods.dashboardForm.init();
    } else {
      elements.unavailable.hide();
    }

    initSortable(elements.formWrapper);
    utils.toggle.dropdowns(true);
    utils.toggle.context('form');
  },
  setSources: function methodSetSources(sources) {
    const options = ['<option value>Please select a source</option>'];

    elements.selectSource
      .empty()
      .append(
        options
          .concat(
            sources.map(function (source) {
              return utils.make.option(source.name, source.id);
            })
          )
          .join('\n')
      )
      .trigger('chosen:updated');

    if (!utils.isDashboard()) {
      utils.set.message('selectSource', {
        company: utils.getCompanyName()
      });
    }
  },
  updateSampleIds: function methodUpdateSampleIds(sampleId, targetContainer) {
    const currentSampleIds = elements.sampleIds
      .val()
      .split(' ')
      .filter(function (id) {
        return id !== '';
      });

    switch (targetContainer) {
      case containers.currentSamples:
        // the sample was moved from available to current
        currentSampleIds.push(sampleId);
        break;
      case containers.availableSamples:
        // the sample was moved from current to available
        currentSampleIds.splice(currentSampleIds.indexOf(sampleId), 1);
        break;
    }

    elements.sampleIds.val(currentSampleIds.join(' '));

    return currentSampleIds;
  },
  updateSelectSources: function methodUpdateSelectSources(companyId) {
    var url = elements.sourcesPath.val();

    if (!companyId) {
      this.setSources([]);

      if (!utils.isDashboard()) {
        utils.set.message('selectCompanyAndSource');
      }
    }

    if (url && companyId) {
      $.get(
        url + '?company_id=' + companyId + '&available_for_invoice=true',
        this.setSources.bind(this)
      );
    }
  }
};

const handlers = {
  selectCompany: function handleSelectCompany(event) {
    const companyId = event.target.value;

    methods.updateSelectSources(companyId);

    if (utils.isDashboard()) {
      methods.samples.toggleAvailable({
        companyId: companyId
      });
    }
  },
  selectSource: function handleSelectSource(event) {
    const sourceId = event.target.value;

    if (utils.isDashboard()) {
      methods.samples.toggleAvailable({
        companyId: elements.selectCompany.val(),
        sourceId: sourceId
      });
    } else {
      if (sourceId) {
        methods.requestNewForm({
          source_id: sourceId
        });
      } else {
        utils.set.message('selectSource', {
          company: utils.getCompanyName()
        });
      }
    }
  },
  sortableOnEnd: function handleSortableOnEnd(event) {
    const data = $(event.item).data();
    let updatedSampleIds, isEmptySet;

    // if target and source ids match, the sample was not moved
    if (event.from.id === event.to.id) return;

    updatedSampleIds = methods.updateSampleIds(data.sampleId, event.to.dataset.invoiceContainer);
    isEmptySet = updatedSampleIds.length === 0;

    methods.setDropdownValues(
      isEmptySet ? {} : { id: data.companyId, name: data.companyName },
      isEmptySet
        ? {}
        : {
            id: data.sourceId,
            name: data.sourceName
          }
    );

    utils.toggle.dropdowns(isEmptySet);

    window.requestAnimationFrame(function () {
      $(event.to).removeClass('is-hovered');

      methods.samples.toggleAvailable(
        isEmptySet
          ? {}
          : {
              companyId: data.companyId,
              sourceId: data.sourceId
            },
        elements.sampleIds.val().split(' ')
      );
    });
  },
  sortableOnMove: function handleSortableOnMove(evt, event) {
    elements.availableSamples.removeClass('is-hovered');
    elements.currentSamples.removeClass('is-hovered');

    $(evt.to).addClass('is-hovered');
  }
};

function initSortable($container) {
  const containerNames = [
    containers.availableSamples,
    containers.unavailableSamples,
    containers.currentSamples
  ];

  const options = {
    group: 'samples',
    sort: false,
    animation: 125,
    onMove: handlers.sortableOnMove,
    onEnd: handlers.sortableOnEnd
  };

  containerNames.forEach(utils.set.element.bind(undefined, $container));
  methods.cacheAllSamples();

  if (
    containerNames.every(function (name) {
      return elements[name].length;
    })
  ) {
    availableSortable = Sortable.create(elements.availableSamples[0], options);
    currentSortable = Sortable.create(elements.currentSamples[0], options);
    utils.set.element($container, 'sampleIds');
  }
}

function initEvents() {
  elements.selectCompany.on('change', handlers.selectCompany);
  elements.selectSource.on('change', handlers.selectSource);

  $(document).on('click', '#Btn_AddToInvoice', function () {
    return $('#add_to_invoice_form').submit();
  });

  $(document).on('click', '#Btn_AddToInvoiceAndView', function () {
    $('#add_to_invoice_form input#view').val(1);
    return $('#add_to_invoice_form').submit();
  });

  $(document).on('change', "input[name='invoice[add_to_invoice]']", function (e) {
    if (this.value === 'existing') {
      return $('#existing_invoices_input').slideDown(300);
    } else {
      return $('#existing_invoices_input').slideUp(300);
    }
  });
}

function init(container) {
  let $wrapper;

  $container = $(container || 'body');
  $wrapper = $container.find('#Invoices_Wrapper');

  if ($wrapper.length) {
    methods.setElements();
    url = elements.newInvoicePath.val();
    context = elements.context.val();

    initEvents();

    if (utils.isDashboard()) {
      methods.requestNewForm({
        get_available_samples: true
      });
    } else {
      switch (context.split('.')[1]) {
        case 'new':
          utils.set.message('selectCompanyAndSource');
          break;
        case 'edit':
          utils.toggle.context('form');
          elements.unavailable.hide();
          initSortable($container);
          break;
      }
    }
  }
}

export const invoices = { init };

import $ from 'jquery';

export const companyList = {
  init: function initCompanyList() {
    $(document).on('change', '#Select_CompanyToView', function () {
      const companyID = this.value;
      window.location.href = `/companies/${companyID}`;
    });

    $('#select_company_submit').on('click', function () {
      window.location = '/companies/' + $('#select_company_id').val();
    });
  }
};

export const searchSamples = {
  initEvents: function initSearchSamplesEvents($container) {
    $container.on('submit', function () {
      $container
        .find('#SearchSamples_Results')
        .addClass('is-hidden')
        .html('<p class="delta">Searching…</p>')
        .removeClass('is-hidden');

      $container.find('.foldup-header').click();
    });
  },
  init: function initSearchSamples(container) {
    const $container = $(container || 'body');

    if ($container.find('#SearchSamples').length) {
      this.initEvents($container);
    }
  }
};

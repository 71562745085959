export const table = {
  saveTableRow: function saveTableRow(event) {
    const $input = $(this);
    const $row = $input.parents("tr").first();
    const property = $input.attr("name");
    const url = $input.data("table-save-path");
    const data = {};
    let value;

    // for now we only have one condition, but this could be expanded later
    if ($input.is(":checkbox")) {
      value = $input.is(":checked");
    }

    if (url && property) {
      data[property] = value;

      $row.addClass("is-loading");

      $.ajax({
        type: "PUT",
        url: url
      }).done(function (response) {
        $row.removeClass("is-loading");
      });
    }
  },
  requestTableRowDetail: function requestTableRowDetail(event) {
    if (event.target.tagName === "TR" || event.target.tagName === "TD") {
      const $row = $(this);
      const url = $row.data("tableRowDetail");
      let $dataRow;

      if ($row.hasClass("is-loading")) return;

      if ($row.hasClass("has-data")) {
        $dataRow = $row.next("tr.data-row");

        $dataRow.find("td").css({
          paddingTop: "0px",
          paddingBottom: "0px"
        });

        $dataRow.find("div").slideUp(400, function () {
          $row.next("tr.data-row").remove();
        });
      } else {
        $row.addClass("is-loading");

        $.get(url, function (response) {
          const tableDetailRow = [
            '<tr class="data-row">',
            '<td colspan="' + $row.parents("table").find("col").length + '">',
            '<div style="display: none">',
            $(response).find(".detail-wrapper").html(),
            "</div></td></tr>"
          ].join("\n");

          $row.after(tableDetailRow);
          $row
              .next()
              .find("div")
              .slideDown(400, function () {
                $row.addClass("has-data");
                $row.removeClass("is-loading");
              });
        });
      }
      $row.toggleClass("has-data", !$row.hasClass("has-data"));
    }
  },
  initEvents: function initTableEvents($container) {
    $container.on(
        "click",
        ".table tr[data-table-row-detail]",
        this.requestTableRowDetail
    );

    $container.on("change", ".table [data-table-save]", this.saveTableRow);
  },
  init: function initTable(container) {
    const $container = $(container || "body");

    if ($container.find(".table")) {
      this.initEvents($container);
    }
  }
};

export function CCUsers(prefix, urls) {
  this.add_user_id = prefix + "_add";
  this.select_id = prefix + "_select";
  this.table_id = prefix + "_table";
  this.select_obj = $("#" + this.select_id);
  this.table_obj = $("#" + this.table_id);
  this.add_user_obj = $("#" + this.add_user_id);
  this.urls = urls;
  this.add_even_registered = false;
}

CCUsers.prototype = {
  register_add_event: function () {
    const _this = this;
    this.add_user_obj.click(function (e) {
      const uid = _this.select_obj.val();
      _this.add_user(uid);
    });
  },
  create_select: function (option_values) {
    this.select_obj.empty();
    const size = option_values.length;
    let entry = null;
    for (let i = 0; i < size; i++) {
      entry = option_values[i];
      this.select_obj.append(
          $("<option></option>")
              .val(entry.value)
              .html(entry.label)
      );
    }
  },
  make_users_into_options: function (data) {
    const options = [];
    const size = data.length;
    let user = null;
    for (var i = 0; i < size; i++) {
      user = data[i];
      options.push({value: user.id, label: user.name});
    }
    return options;
  },
  remove_id: function (user) {
    return this.table_id + "_" + user.id;
  },
  make_remove_anchor: function (user) {
    return (
        "<a href='#' class='destroy' id='" +
        this.remove_id(user) +
        "'>Remove</a>"
    );
  },
  create_table_row: function (user) {
    return (
        "<tr><td>" +
        [user.name, user.email, this.make_remove_anchor(user)].join(
            "</td><td>"
        ) +
        "</td></tr>"
    );
  },
  create_table_headers: function () {
    let headers;
    headers =
        "<colgroup><col class='t25'><col class='t50'><col></colgroup>";
    headers +=
        "<thead><tr><th>User Name</th><th>Email Address</th><th>Actions</th></tr></thead>";
    return headers;
  },
  register_remove_event: function (user) {
    const _this = this;
    const remove_obj = $("#" + this.remove_id(user));
    remove_obj.unbind("click");
    remove_obj.click(function (event) {
      event.preventDefault();
      _this.remove_user(user);
    });
  },
  create_table: function (users) {
    this.table_obj.empty();
    const size = users.length;
    let user = null;

    if (size > 0) {
      this.table_obj.append(this.create_table_headers);
    } else {
      this.table_obj.append(this.create_table_headers);
      this.table_obj.append(
          '<tr><td colspan="3">No users marked.</td></tr>'
      );
    }

    for (let i = 0; i < size; i++) {
      user = users[i];
      this.table_obj.append(this.create_table_row(user));
      this.register_remove_event(user);
    }

    if (!this.add_event_registered) {
      this.register_add_event();
      this.add_event_registered = true;
    }
  },
  populate: function () {
    const _this = this;
    $.get(this.urls.availableUsers, function (data) {
      _this.create_select(_this.make_users_into_options(data));
      _this.update_select();
    });

    $.get(this.urls.ccUsers, function (data) {
      _this.create_table(data);
    });
  },
  update_select: function () {
    $("select").trigger("chosen:updated");
  },
  add_user: function (uid) {
    const _this = this;
    const user_id = uid;
    if (!user_id) this.select_obj.val();
    $.post(this.urls.addUser, {id: user_id}, function (data) {
      _this.populate();
    });
  },
  remove_user: function (user) {
    const _this = this;
    $.post(this.urls.removeUser, {id: user.id}, function (data) {
      _this.populate();
    });
  }
};

import $ from 'jquery';

export const unitInput = {
  init: function initUnitInput() {
    $(document).on('mouseenter', '.unit-input-field', function () {
      $(this).parent().addClass('hover');
    });

    $(document).on('mouseleave', '.unit-input-field', function () {
      $(this).parent().removeClass('hover');
    });

    $(document).on('focus', '.unit-input-field:not([readonly])', function () {
      $(this).parent().addClass('active');
    });

    $(document).on('blur', '.unit-input-field', function () {
      $(this).parent().removeClass('active');
    });
  }
};

import $ from 'jquery';
import moment from 'moment';
import {getAllBeforeIndex, getAllAfterIndex} from '@utils';

export const filter = {
  setDates: function setDates(start, end, target) {
    $("#FilterDateInput-" + target + "_Start")
        .val(start.format("YYYY-MM-DD"))
        .trigger("change")
        .datepicker("update");
    $("#FilterDateInput-" + target + "_End")
        .val(end.format("YYYY-MM-DD"))
        .trigger("change")
        .datepicker("update");
  },
  getTheNewDate: function getTheNewDate(date, range, polarity) {
    return polarity ? date.add("days", range) : date.subtract(range, "days");
  },
  setInitialRange: function setInitialFilterRange() {
    let start = moment();
    const end = moment();
    let initialRange = 0;

    initialRange = getAllAfterIndex(
        $(".filter-preset-list")
            .first()
            .find(":selected")
            .val(),
        "_"
    );

    start = this.getTheNewDate(start, initialRange, false);

    $(".filter").each(
        function (index, element) {
          const target = $(element).attr("id");
          this.setDates(start, end, target);
        }.bind(this)
    );
  },
  clickFilterDownload: function clickFilterDownload(event) {
    const $eventTarget = $(event.currentTarget);
    const data = {};
    const filter = $eventTarget.parents(".filter");
    const url = filter.data("submitUrl");

    filter.find("input").each(function (index, input) {
      data[$(input).attr("name")] = $(input).val();
    });

    event.preventDefault();
    window.location = $eventTarget.attr("href") + "?" + $.param(data);
  },
  changeFilterInput: function changeFilterInput(event) {
    const $eventTarget = $(event.currentTarget);
    const data = {};
    const filter = $eventTarget.parents(".filter");
    const url = filter.data("submitUrl");

    filter.find("input").each(function (index, input) {
      if ($(input).attr("name") && $(input).val() !== "") {
        data[$(input).attr("name")] = $(input).val();
      }
    });

    return $.ajax({
      url: url,
      data: data,
      dataType: "script"
    });
  },
  changeFilterPreset: function changeFilterPreset(event) {
    const $filterSelect = $(event.currentTarget);
    const filterPreset = $filterSelect.val();
    let start = moment();
    const end = moment();
    const polarity = getAllBeforeIndex(filterPreset, "_") === "+";
    const range = getAllAfterIndex(filterPreset, "_");
    const target = $filterSelect.parents(".filter").attr("id");

    start = this.getTheNewDate(start, range, polarity);
    this.setDates(start, end, target);
  },
  initEvents: function initFilterEvents($container) {
    $container.on(
        "change",
        ".filter[data-submit-url] input",
        this.changeFilterInput.bind(this)
    );

    $container.on(
        "change",
        ".filter-preset-list",
        this.changeFilterPreset.bind(this)
    );

    $container.on("click", ".filter-dl a", this.clickFilterDownload.bind(this));
  },
  init: function initFilters(container) {
    const $container = $(container || "body");

    if ($container.find(".filter")) {
      this.initEvents($container);

      if ($(".filter-preset-list").length > 0) {
        this.setInitialRange();
      }
    }
  }
};

/**
 *
 * @param {string} link A selector string for a link.
 * @param {string} association An internal association string.
 * @param {string} content The content of the field(s) to insert.
 */
export function addFields(link, association, content) {
  const newId = new Date().getTime();
  const regExp = new RegExp("new_" + association, "g");
  const $parent = $(link).parent();

  $parent.before(content.replace(regExp, newId));
  LabPortal.init($parent.prev()[0]);
}

/**
 * Convert a string of HTML into a DOM element.
 *
 * @param {string} html The HTML to process.
 *
 * @return {Element}
 */
export function createElementFromHTML(html) {
  const div = document.createElement("div");
  div.innerHTML = html.trim();

  return div.firstElementChild;
}

/**
 * Converts an object into a valid query string.
 *
 * @param {Object} params The object to parse.
 *
 * @return {string}
 */
export function createQueryString(params) {
  return Object.keys(params)
      .reduce(function (queries, key) {
        return queries.concat([key + "=" + params[key]]);
      }, [])
      .join("&");
}

/**
 * Closes the currently-open overlay by finding the close button
 * and clicking it.
 */
export function closeOverlay() {
  $(".overlay-content-title__button").click();
}

/**
 * Formats a number as currency. Defaults to U.S. Dollars.
 *
 * @param {number} price The price to format.
 * @param {string} [unit="$"] The unit to append.
 */
export function formattedPrice(price, unit) {
  return (unit || "$") + price.toFixed(2);
}

/**
 * Indexes a string by index of choice and returns all characters
 * before the index.
 *
 * @param {string} string The string to search.
 * @param {string} index The string to split against.
 *
 * @return {string}
 */
export function getAllBeforeIndex(string, index) {
  const p = string.indexOf(index);
  if (p === -1) {
    return false;
  } else {
    return string.slice(0, p);
  }
}

/**
 * Indexes a string by index of choice and returns all characters
 *  after the index.
 *
 * @param {string} string The string to search.
 * @param {string} index The string to split against.
 *
 * @return {string}
 */
export function getAllAfterIndex(string, index) {
  const p = string.indexOf(index);
  if (p === -1) {
    return false;
  } else {
    return string.substring(p + index.length);
  }
}

/**
 * Return hash as string without leading symbol.
 *
 * @return {string}
 */
export function getHashContent() {
  if (!window.location.hash) return null;
  return window.location.hash.substr(1);
}

/**
 * Removes fields connected to a given link.
 *
 * @param {string} link A selector string pointing to a link.
 */
export function removeFields(link) {
  $(link)
      .prev("input[type=hidden]")
      .val("1");

  $(link)
      .closest(".fields")
      .hide();
}

/**
 * Parse a JSON-style string set within an HTML attribute of the following format:
 *
 * `attr="'{'key1': 'value1', 'key2': ['value2-1', 'value2-2']}'"`
 *
 * The single quotes are necessary for proper HTML validation, but need to be
 * transformed to double quotes for proper JSON validation.
 *
 * @param {string} str A JSON-styled string.
 *
 * @return {Object}
 */
export function parseOptions(str) {
  return JSON.parse(str.substring(1, str.length - 1).replace(/'/g, '"'));
}

/**
 * Requests a chunk of HTML from a remote source.
 *
 * @param {string} url Url to request the template from.
 * @param {Function} callback A callback to execute using the processed template.
 * @param {string} [selector] Optional selector for an element to extract.
 */
export function requestTemplate(url, callback, selector) {
  selector = selector || ".wrapper";

  $.get(url, function (response) {
    const $template = $(response).find(selector);
    const $div = $(document.createElement("div"));

    if ($template.length) {
      $div.html($template[0].outerHTML);
    }

    callback($div.html());
  });
}

/**
 * Sets the current URL hash using one or two section names.
 * The names are space-separated if both are provided.
 *
 * @param {string} section A section name.
 * @param {string} [subsection] A subsection name.
 */
export function setHash(section, subsection) {
  const hashArray = [section];

  if (subsection) {
    hashArray.push(subsection);
  }

  window.location.hash = hashArray.join("-");
}

/**
 * Convert a string to `camelCase`. Accepts both `snake_case` and
 * `kebab-case`.
 *
 * @param {string} string The input value
 *
 * @return {string}
 */
export function snakeToCamel(string) {
  return string.replace(/([-_][a-z])/gi, function ($1) {
    return $1
        .toUpperCase()
        .replace("-", "")
        .replace("_", "");
  });
}

/**
 * Simulates string templating. Enclose portions of a string in braces,
 *     then pass in an object with keys whch match the braced regions
 *     to replace them at runtime.
 *
 * @example
 * // return 'Hello world!'
 * supplant('{greeting} {location}!', { greeting: 'Hello', location: 'world' });
 *
 * @param {string} str String to interpolate.
 * @param {object} obj An object containing key-value pairs.
 *
 * @return {string}
 */
export function supplant(str, obj) {
  return str.replace(/{([^{}]*)}/g, function (a, b) {
    const r = obj[b];
    return typeof r === "string" || typeof r === "number" ? r : a;
  });
}

/**
 * Given a id which matches a known icon, generates a `use` reference in a valid SvG wrapper.
 * Required a size to control the final rendering.
 *
 * @param {string} contents The SVG contents.
 * @param {number} size The size of the produced SVG.
 *
 * @return {string}
 */
export function svgIcon(id, size) {
  const attrs = {
    xmlns: "http://www.w3.org/2000/svg",
    width: size,
    height: size
  };

  const attrString = Object.keys(attrs).reduce(function (final, attrKey) {
    return (
        `${final} ${attrKey}="${(attrs[attrKey] || "").toString()}"`
    );
  }, " ");

  return (
      `<svg ${attrString}><use xlink:href="#icons-${id}" /></svg>`
  );
}

import $ from 'jquery';

export const datepicker = {
  init: function initDatepicker(container) {
    container = container || "body";

    $(container)
        .find("[data-behavior='datepicker']")
        .datepicker({
          format: "yyyy-mm-dd"
        })
        .on("changeDate", function (event) {
          $(this).datepicker("hide");
        });
  }
};

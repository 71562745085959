export const tableFilter = (function () {
  const elements = {};
  const api = {};
  const tableData = {};

  const filterMethods = {
    multiColumn: function methodsSearchByMultiColumn(
        columnIndexes,
        searchValue
    ) {
      return function (settings, data) {
        return columnIndexes.reduce(function (isMatch, colIndex) {
          if (isMatch) return true;
          return data[colIndex].toLowerCase().indexOf(searchValue) !== -1;
        }, false);
      };
    },
    dateRange: function methodsSearchByDateRange(filterName) {
      const columns = api[filterName].settings()[0].aoColumns;
      const rangeControls = methods.controls.getRangeControls(filterName);
      const dateColumn = columns.find(function (column) {
        return (column.name || column.sName) === "date";
      });

      return function (settings, data, dataIndex) {
        let startDate, endDate, rowDate;

        if (settings.sTableId !== filterName || !dateColumn) return true;

        rowDate = moment(data[dateColumn.idx]);

        startDate = rangeControls.start.val();
        endDate = rangeControls.end.val();

        if (startDate === "" && endDate === "") return true;

        if (startDate === "" && endDate !== "") {
          endDate = moment(endDate);
          return rowDate.isBefore(endDate) || rowDate.isSame(endDate);
        }

        if (startDate !== "" && endDate === "") {
          startDate = moment(startDate);
          return rowDate.isAfter(startDate) || rowDate.isSame(startDate);
        }

        startDate = moment(startDate);
        endDate = moment(endDate);

        return (
            (rowDate.isAfter(startDate) || rowDate.isSame(startDate)) &&
            (rowDate.isBefore(endDate) || rowDate.isSame(endDate))
        );
      };
    }
  };

  var methods = {
    search: {
      init: function methodsSearchInit(filterName, $filter) {
        const filterType = $filter.data("tableFilterTargetType");
        if (
            typeof methods.search.execute[filterType] === "function" &&
            api[filterName]
        ) {
          methods.search.execute[filterType](
              api[filterName],
              $filter,
              filterName
          );
        }
      },
      execute: {
        column: function methodsSearchExecuteColumn(api, $filter) {
          const columns = api.settings()[0].aoColumns;
          const targetColumns = methods.control.getTargetColumns(
              $filter,
              columns
          );
          const searchVal = $filter.val();

          if (targetColumns.length === 1) {
            api
                .column(targetColumns)
                .search(searchVal)
                .draw();
          } else {
            $.fn.dataTable.ext.search.push(
                filterMethods.multiColumn(targetColumns, searchVal)
            );
            api.draw();
            $.fn.dataTable.ext.search.pop();
          }
        },
        paging: function methodsSearchExecutePaging(api, $filter) {
          const pageLength = parseInt($filter.val(), 10);

          if (!Number.isNaN(pageLength)) {
            api.page.len(pageLength).draw("page");
          }
        },
        range: function methodsSearchExecuteRange(api, $filter, filterName) {
          if ($filter.data("tableFilterRangePreset") !== undefined) {
            methods.controls.setRange(filterName);
          } else {
            api.draw();
          }
        }
      },
      by: {
        multiColumn: function methodsSearchByMultiColumn(
            columnIds,
            searchValue
        ) {
          return function (settings, data, dataIndex) {
            return columnIds.reduce(function (isMatch, colIndex) {
              if (isMatch) return true;
              return data[colIndex].toLowerCase().indexOf(searchValue) !== -1;
            }, false);
          };
        },
        dateRange: function methodsSearchByDateRange(filterName) {
          const rangeControls = methods.controls.getRangeControls(filterName);

          return function (settings, data, dataIndex) {
            const columns = settings.aoColumns;
            const dateColumn = columns.find(function (column) {
              return (column.name || column.sName) === "date";
            });
            let startDate, endDate, rowDate;

            if (!dateColumn) return true;

            startDate = moment(rangeControls.start.val());
            endDate = moment(rangeControls.end.val());
            rowDate = moment(data[dateColumn.idx]);

            if (startDate === null && endDate === null) return true;
            if (startDate === null && endDate !== null)
              return rowDate.isBefore(endDate);
            if (startDate !== null && endDate === null)
              return rowDate.isAfter(startDate);
            return rowDate.isAfter(startDate) && rowDate.isBefore(endDate);
          };
        }
      }
    },
    control: {
      getTargetColumns: function methodsControlGetTargetColumns(
          $filter,
          columns
      ) {
        const target = $filter.data("tableFilterColumn");

        if (!target) return [];

        if (target instanceof Array) {
          return target.map(
              function (targetName) {
                return this._getColumnIndex(columns, targetName);
              }.bind(this)
          );
        }

        return [this._getColumnIndex(columns, target)];
      },
      _getColumnIndex: function methodsControl_getColumnIndex(
          columns,
          targetName
      ) {
        return (
            columns.find(function (column) {
              return (column.name || column.sName || "") === targetName;
            }) || {}
        ).idx;
      }
    },
    controls: {
      setRange: function methodsControlsSetRange(filterName) {
        const rangeControls = this.getRangeControls(filterName);
        const rangePresetControl = elements.filterControls[filterName]
            .get()
            .find(function (element) {
              return $(element).data("tableFilterRangePreset") !== undefined;
            });
        const end = moment();
        const start = moment();
        let rangeAmount;

        if (rangePresetControl && rangeControls.start && rangeControls.end) {
          rangeAmount = parseInt($(rangePresetControl).val(), 10);
          rangeControls.end.datepicker("update", end.toDate());
          rangeControls.start.datepicker(
              "update",
              rangeAmount === -1
                  ? null
                  : start.subtract(rangeAmount, "days").toDate()
          );
        }
      },
      getRangeControls: function methodsControlsGetRangeControls(filterName) {
        return ["start", "end"].reduce(function (controls, bound) {
          controls[bound] = elements.filterControls[filterName].filter(function (
              index,
              element
          ) {
            return $(element).data("tableFilterDateBound") === bound;
          });

          return controls;
        }, {});
      }
    },
    actions: {
      download: {
        csv: function methodsDownloadCsv(filterName, $button) {
          const idColumn = api[filterName]
              .settings()[0]
              .aoColumns.find(function (column) {
                return column.name === "id";
              });
          let data = api[filterName].rows({search: "applied"}).data();
          const originalPath = $button.attr("href");
          let downloadPath;

          if (idColumn) {
            data = data
                .map(function (row) {
                  return row[idColumn.idx].toString();
                })
                .toArray();

            downloadPath = [
              originalPath,
              "?",
              data
                  .map(function (id) {
                    return "ids[]=" + id.toString();
                  })
                  .join("&")
            ].join("");

            $button.attr("href", encodeURI(downloadPath));
            $button[0].click();

            window.requestAnimationFrame(function () {
              $button.attr("href", originalPath);
            });
          }
        }
      }
    }
  };

  function initEvents() {
    elements.tableFilter
        .on("change keyup", function (event) {
          const $filter = $(event.target);
          const filterName = $(event.delegateTarget).data("tableFilter");

          methods.search.init(filterName, $filter);
        })
        .on("click", function (event) {
          let $target = $(event.target);
          const filterName = $(event.delegateTarget).data("tableFilter");
          let action;

          if ($target.parent("a")) {
            $target = $target.parent();
          }

          action = $target.data("tableFilterAction");

          if (action !== undefined) {
            event.preventDefault();
            action = action.split(":");

            if (typeof methods.actions[action[0]][action[1]] === "function") {
              methods.actions[action[0]][action[1]](filterName, $target);
            }
          }
        });
  }

  function init(container) {
    const $container = $(container);

    elements.tableFilter = $container.find("[data-table-filter]");
    elements.table = {};
    elements.filterControls = {};
    elements.filterActions = {};

    elements.tableFilter.each(function (index, element) {
      const $element = $(element);
      const filterName = $element.data("tableFilter");

      elements.table[filterName] = $element.find("[data-table-filter-table]");
      elements.filterControls[filterName] = $element.find(
          "[data-table-filter-controls] :input"
      );
      elements.filterActions[filterName] = $element.find(
          "[data-table-filter-action]"
      );

      elements.table[filterName].on("init.dt", function () {
        api[filterName] = elements.table[filterName].find("table").DataTable();
        tableData[filterName] = api[filterName].data();
        $.fn.dataTable.ext.search.push(filterMethods.dateRange(filterName));

        methods.controls.setRange(filterName);
      });
    });

    initEvents();
  }

  return {
    init: init
  };
})();

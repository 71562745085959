import $ from 'jquery';

export const analysis = {
  init: function initAnalysis(container) {
    const $container = $(container || 'body');
    const queryParams = this.parseQueryString();

    if ($container.find('#analysis_form').length === 0) return;

    this.initializeFormValues($container, queryParams);
    this.bindEventHandlers($container);
  },

  parseQueryString: function parseQueryString() {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    return vars.reduce((queryString, variable) => {
      const pair = variable.split('=');
      const key = pair[0];
      const value = pair[1];

      if (typeof queryString[key] === 'undefined') {
        queryString[key] = value;
      } else if (typeof queryString[key] === 'string') {
        queryString[key] = [queryString[key], value];
      } else {
        queryString[key].push(value);
      }

      return queryString;
    }, {});
  },

  initializeFormValues: function initializeFormValues($container, queryParams) {
    const fieldMappings = {
      start_date: '#ReportDateInput_Start',
      end_date: '#ReportDateInput_End',
      company_id: '#Select_ReportBuilderCustomer',
      source_id: '#Select_ReportBuilderSource',
      grade: '#ReportGradeInput'
    };

    Object.entries(fieldMappings).forEach(([param, selector]) => {
      if (param in queryParams) {
        $container.find(selector).val(queryParams[param]);
      }
    });
  },

  bindEventHandlers: function bindEventHandlers($container) {
    const self = this;

    $container.find('#Select_ReportBuilderCustomer').change(function () {
      $container.find('#analysis_form').submit();
    });

    $container.find('#generate_report').click(function () {
      if (!self.validateSelection($container)) return false;

      const params = self.getReportParams($container);
      window.location.href = `results?${self.buildQueryString(params)}`;
      return false;
    });

    $container.find('#down_csv').click(function () {
      if (!self.validateSelection($container)) return false;

      const params = self.getReportParams($container);
      window.location.href = `/report/download.csv?${self.buildQueryString(params)}`;
      return false;
    });
  },

  validateSelection: function validateSelection($container) {
    const companySelected = $container.find('#Select_ReportBuilderCustomer').val() !== '';
    const sourceSelected = $container.find('#Select_ReportBuilderSource').val() !== '';

    if (!companySelected && !sourceSelected) {
      alert('Select a Company or Source');
      return false;
    }
    return true;
  },

  getReportParams: function getReportParams($container) {
    return {
      company_id: $container.find('#Select_ReportBuilderCustomer').val(),
      start_date: $container.find('#ReportDateInput_Start').val(),
      end_date: $container.find('#ReportDateInput_End').val(),
      source_id: $container.find('#Select_ReportBuilderSource').val(),
      grade: $container.find('#ReportGradeInput').val()
    };
  },

  buildQueryString: function buildQueryString(params) {
    return Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
  }
};

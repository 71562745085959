import $ from 'jquery';
import {chosen} from '@plugins';
import {svgIcon} from '@utils';

export const dataTables = {
  init: function initDataTables(container) {
    let $tables;

    container = container || 'body';
    $tables = $(container).find('[data-datatables]');

    if ($tables.length) {
      $tables.each(
          function (index, table) {
            const $table = $(table);
            $table.dataTable(this._getConfig($table));
          }.bind(this)
      );
    }
  },
  _getConfig: function getDataTablesConfig($table) {
    const options = $table.data('datatables');
    const defaultOptions = this._getDefaultOptions($table);

    return $.extend(true, {}, defaultOptions, options);
  },
  _getDefaultOptions: function getDataTablesDefaultOptions($table) {
    const requestDetail = this._requestTableRowDetail;
    const options = {
      buttons: function () {
        return [];
      },
      dom: function () {
        return [
          "<'dataTables_wrapperHead'",
          "<'dataTables_wrapperHead__title'>",
          "<'dataTables_wrapperHead__left'l>",
          "<'dataTables_wrapperHead__right'fB>>",
          'tip'
        ].join('');
      },
      language: function () {
        const arrows = ['left', 'right', 'beginning', 'end'].reduce(function (final, direction) {
          const id = `nav_arrow_${direction}`;

          final[direction] = svgIcon(id, 12);

          return final;
        }, {});

        return {
          paginate: {
            first: `${arrows.beginning}First Page`,
            previous: `${arrows.left}Previous Page`,
            next: `Next Page${arrows.right}`,
            last: `Last Page${arrows.end}`
          }
        };
      },
      drawCallback: function () {
        return function (settings) {
          const $table = $(this);
          const $wrapperHead = $table.siblings('.wrap-head');
          const $lengthSelector = $wrapperHead.find('.dataTables_length select');

          if ($lengthSelector.length && !$lengthSelector.data('chosen')) {
            chosen.init($lengthSelector.parent()[0]);
          }
        };
      },
      initComplete: function () {
        return function (settings) {
          const api = new $.fn.dataTable.Api(settings);
          const $table = $(api.table().node());

          if ($table.find('caption').length) {
            $('.dataTables_wrapperHead__title').html(
                ['<h3 class="flush--bottom">', $table.find('caption').html(), '</h3>'].join('')
            );
          }

          chosen.init($table.parent().find('.dataTables_length')[0]);

          $table.removeAttr('data-datatables');
        };
      }
    };

    return Object.keys(options).reduce(function (final, key) {
      final[key] = options[key]();
      return final;
    }, {});
  },
  _requestTableRowDetail: function requestDataTablesRowDetail(event) {
    if (event.target.tagName === 'TR' || event.target.tagName === 'TD') {
      const $row = $(this);
      const url = $row.data('tableRowDetail');
      let $dataRow;

      if ($row.hasClass('is-loading')) return;

      if ($row.hasClass('has-data')) {
        $dataRow = $row.next('tr.data-row');

        $dataRow.find('td').css({
          paddingTop: '0px',
          paddingBottom: '0px'
        });

        $dataRow.find('div').slideUp(400, function () {
          $row.next('tr.data-row').remove();
        });
      } else {
        $row.addClass('is-loading');

        $.get(url, function (response) {
          const tableDetailRow = [
            '<tr class="data-row">',
            '<td colspan="' + $row.parents('table').find('col').length + '">',
            '<div style="display: none">',
            $(response).find('.detail-wrapper').html(),
            '</div></td></tr>'
          ].join('\n');

          $row.after(tableDetailRow);
          $row
              .next()
              .find('div')
              .slideDown(400, function () {
                $row.addClass('has-data');
                $row.removeClass('is-loading');
              });
        });
      }
      $row.toggleClass('has-data', !$row.hasClass('has-data'));
    }
  }
};

$.fn.dataTable.ext.buttons.modal = {
  action: function (e, dt, node, config) {
    $(node.data('modal')).modal();
  }
};

$.fn.dataTable.ext.buttons.linkTo = {
  action: function (e, dt, node, config) {
    window.location.href = node.data('linkTo');
  }
};

export const BASE_PADDING = 21;
export const SWITCHER_BUTTON_PREFIX = "SwitcherButton_";
export const SWITCHER_TARGET_PREFIX = "SwitcherTarget_";
export const TAB_SWAP_BUTTON_PREFIX = "TabSwapNav_";
export const TAB_SWAP_TARGET_PREFIX = "TabSwapTarget_";
export const MODAL_BUTTON_PREFIX = "ModalBtn_";
export const MODAL_TARGET_PREFIX = "Modal_";
export const clickResizeTriggers = ".foldup-modal .foldup-title";
export const changeResizeTriggers = ".modal input[type=radio]";
export const chosenObjectsWithSearch = [
  {id: "#Select_CompanyToView", search: true},
  {id: "#Select_NewSampleForCompany", search: true},
  {id: "#Select_ReportBuilderCustomer", search: true},
  {id: "#Select_ReportBuilderSource", search: true},
  {id: "#Select_SampleStateList", search: true},
  {id: "#address_countries", search: true},
  {id: "#address_region_states", search: true},
  {id: "#sample_source_id", search: false}
];

import $ from 'jquery';
import 'jquery-modal';
import Handlebars from 'handlebars';
import { quotes } from '@modules';
import { MODAL_TARGET_PREFIX } from '@constants';

export const modals = (function () {
  const props = {
    _default: {
      id: 'Modal_Default',
      title: 'Default Title'
    },
    confirm: {
      id: 'Modal_Confirm',
      title: 'Confirm Action'
    }
  };

  function createModal(type, message) {
    const modalTemplate = $(`#${type}-modal-template`).html();
    const modalProps = props[type];
    let preparedTemplate;

    if (modalProps && modalTemplate) {
      preparedTemplate = Handlebars.compile(modalTemplate);

      $(document.body).append(
        `<div class="l-modal">${preparedTemplate({
          id: modalProps.id,
          title: modalProps.title,
          message: message
        })}</div>`
      );

      $(`#${modalProps.id}`).modal();
    }
  }

  function initEvents() {
    $(document).on('click', '#Btn_ChangeQuoteContact', function (event) {
      const id = $('#Select_QuoteContact').val();

      $.get(`/contacts/${id}.json`).done(function (data) {
        // sloppy method for now to check for view updates
        if ($('div#tpl_quotes_contactInfo').length) {
          quotes.view.update.contactInfo(data);
        }

        $.modal.close();
      });
    });

    $(document).on('click', '#Btn_ConfirmAndSendReport', function (event) {
      event.preventDefault();
      $('#transmission_submit').submit();
    });

    $(document).on('click', '#Btn_CreateNewQuoteForCompany', function (event) {
      const company_url = $('#Select_NewQuoteForCompany option:selected').val();
      const contact_id = $('#Select_QuoteContact option:selected').val();
      event.preventDefault();
      const action = company_url.replace('/new', '');
      const company_id = company_url.replace('/quotes/new', '').replace('/companies/', '');
      $('#CreateQuotePost').attr('action', action);
      $('#contact_id').val(contact_id);
      $('#company_id').val(company_id);
      $('#CreateQuotePost').submit();
    });

    $(document).ready(function () {
      $('#Btn_CreateNewQuoteForCompany').attr('disabled', 'disabled');
    });

    $(document).on('change', '#Select_NewQuoteForCompany', function (event) {
      $('#Btn_CreateNewQuoteForCompany').removeAttr('disabled');
      const target = $('#Select_NewQuoteForCompany option:selected').val();
      const url = target.replace('quotes/new', 'contacts');
      $.ajax({
        url: url,
        method: 'GET'
      }).done(function (data) {
        $('#Select_QuoteContact').find('option').remove();
        $.each(data, function (index, value) {
          $('#Select_QuoteContact').append(
            $('<option></option>').attr('value', value['id']).text(value['name'])
          );
        });
        $('#Select_QuoteContact').trigger('chosen:updated');
      });
    });

    $(document).on('click', '#Btn_CreateNewSampleForCompany', function (event) {
      const target = $('#Select_NewSampleForCompany option:selected').val();
      event.preventDefault();
      window.location.href = target;
    });

    $(document).on('click', '#Btn_SubmitForApproval', function (event) {
      let emptyCount = 0;
      const modalWidth = 0;
      let target = '';

      event.preventDefault();

      $('.unit-input-field:not([readonly])').each(function (index, elem) {
        if (!elem.value) {
          emptyCount = emptyCount + 1;
        }
      });

      target = [
        '#',
        MODAL_TARGET_PREFIX,
        emptyCount > 0 ? 'TestFieldsNotComplete' : 'ConfirmSubmitForApproval'
      ].join('');

      $(target).modal();
    });

    $(document).on('click', '#Btn_ConfirmAndSubmitResults', function () {
      $('form.edit_sample').trigger('submit');
    });

    $(document)
      .on($.modal.BEFORE_OPEN, function (event, modal) {
        const width = modal.$elm.find('.abs-modal').data('modalWidth');

        modal.$elm.css('width', width);

        if ($('body').hasClass('has-overlay')) {
          modal.$blocker.css('transform', $(document.body).css('transform').replace('-', ''));
        }
      })
      .on($.modal.OPEN, function (event, modal) {
        const $cloneTestIdField = modal.$elm.find('#clone_test_id');

        if ($cloneTestIdField.length) {
          $cloneTestIdField.val(modal.$anchor.data('testId'));
        }
      })
      .on('click', '#Btn_CloseModal', function (event) {
        $.modal.close();
        event.preventDefault();
      });
  }

  function init() {
    initEvents();
    $.modal.defaults.showClose = false;
  }

  function destroy(type) {
    const modal = $(`#${type}`);
    if (!modal.is(':visible')) {
      modal.remove();
    }
  }

  return {
    init: init,
    createModal: createModal,
    destroy: destroy
  };
})();

import $ from 'jquery';
import Handlebars from 'handlebars';
import {scrollLock} from '@modules';
import {createElementFromHTML, parseOptions, requestTemplate} from '@utils';

export const overlay = {
  init: function initOverlay() {
    $(document).on(
        'click',
        '[data-lp-overlay]',
        function (event) {
          const $target = $(event.currentTarget);

          // prevent double-clicks
          if ($target.hasClass('is-active')) return false;

          event.stopImmediatePropagation();
          event.preventDefault();

          this.create($target);
        }.bind(this)
    );
  },
  /**
   * A module for creating overlays using remotely-loaded content.
   *
   * @param {JQuery} $target The target button which initiated the overlay.
   */
  create: function overlay($target) {
    /**
     * Internal instance of the `lockScrolling` module.
     * @type {Object}
     */
    const scrolling = scrollLock();

    /**
     * A top margin which should apply to the scrolling placement.
     * @type {number}
     */
    const topMargin = scrolling.getTopMargin();

    /**
     * The Handlebars template HTML, stored as a string.
     * @type {string}
     */
    const templateHTML = $(lp('template', true)).html();

    /**
     * The compiled Handlebars template, ready for rendering.
     */
    const template = Handlebars.compile(templateHTML);

    let title, size, url, params;

    params = parseOptions($target.attr(lp('params')));

    size = params.size;
    title = params.title;
    url = params.url;

    init();

    ////////////////////

    /**
     * Initializes the component scripting.
     */
    function init() {
      const splitUrl = url.split('|');

      scrolling.cachePosition();
      scrolling.setState(true);

      $target.addClass('is-active');
      requestTemplate(splitUrl[0], createOverlay, splitUrl[1]);
    }

    /**
     * Creates a new overlay using an HTML string as content.
     *
     * @param {string} overlayContent HTML content to insert.
     */
    function createOverlay(overlayContent) {
      const overlayString = template({
        size: size,
        title: title,
        content: overlayContent
      });

      const overlay = createElementFromHTML(overlayString);

      $(document).on('click', handleCloseButton.bind(undefined, overlay));
      $target.removeClass('is-active');
      setOverlay(overlay);
    }

    /**
     * Destroys an overlay instance by removing it from the DOM.
     *
     * @param {HTMLElement} overlay The overlay to destroy.
     */
    function destroyOverlay(overlay) {
      window.requestAnimationFrame(function () {
        toggleOverlay(overlay);

        setTimeout(function () {
          $(document).find('.overlay').remove();
          scrolling.setState(false);
        }, 750);
      });
    }

    /**
     * Set the newly-created overlay into the DOM.
     *
     * @param {HTMLElement} overlay The current overlay;
     */
    function setOverlay(overlay) {
      window.requestAnimationFrame(function () {
        const overlayPosition = scrolling.getSafeOverlayPosition();

        document.body.appendChild(overlay);

        Array.prototype.slice.call(overlay.children).forEach(function (child) {
          child.style.top = overlayPosition.top;
          child.style.marginTop = overlayPosition.marginTop || '';
        });

        initializeOverlay(overlay);
      });
    }

    /**
     * Initialize the contents of the overlay
     * @param {HTMLElement} overlay The current overlay.
     */
    function initializeOverlay(overlay) {
      const $overlay = $(overlay);
      const $form = $overlay.find('form');

      $form.on('submit', handleFormSubmit.bind(undefined, overlay, $form));
      window.LabPortal?.init(overlay);

      window.requestAnimationFrame(function () {
        toggleOverlay(overlay);
      });
    }

    /**
     * Handler for clicks on the overlay's close button.
     *
     * @param {HTMLElement} overlay The active overlay object.
     * @param {JQuery.Event} event The event object.
     */
    function handleCloseButton(overlay, event) {
      const $target = $(event.target);

      if ($target.length && $target[0].hasAttribute(lp('close')) && overlay) {
        $target.off('click', handleCloseButton);

        window.requestAnimationFrame(function () {
          destroyOverlay(overlay);
        });

        event.preventDefault();
      }
    }

    /**
     * Handler for when a form is submitted within an overlay.
     */
    function handleFormSubmit(overlay, $form, event) {
      if ($form.data('remote')) {
      } else {
        toggleOverlay(overlay);
      }

      event.preventDefault();
    }

    /**
     * Toggle visibility of an overlay.
     *
     * @param {HTMLElement} overlay The overlay to toggle.
     */
    function toggleOverlay(overlay) {
      $(overlay).toggleClass('is-visible');
    }

    /**
     * Generates a selector string by appending a base value.
     *
     * @param {string} name The unique portion of the selector name.
     * @param {boolean} [isSelector] Should we wrap this as a selector string?
     *
     * @return {string}
     */
    function lp(name, isSelector) {
      const selector = `data-lp-overlay-${name}`;

      return isSelector ? `[${selector}]` : selector;
    }
  }
};

import $ from 'jquery';
import { TAB_SWAP_TARGET_PREFIX, TAB_SWAP_BUTTON_PREFIX } from '@constants';
import { getAllAfterIndex, getHashContent } from '@utils';

export const menu = {
  tabs: {
    init: function initTabs($tabWrap) {
      let initialTab, initialWrap, tabSection;

      $tabWrap
        .css({
          width: '100%'
        })
        .addClass('is-hidden');

      tabSection = getAllAfterIndex($('.island-tabwrap:first-of-type').attr('id'), '_');

      initialWrap = TAB_SWAP_TARGET_PREFIX + tabSection;
      initialTab = TAB_SWAP_BUTTON_PREFIX + tabSection;

      $(`#${initialTab}`).addClass('is-active');
      $(`#${initialWrap}`).removeClass('is-hidden');
    },
    switch: function switchTabs(target) {
      const toShow = '#' + TAB_SWAP_TARGET_PREFIX + target,
        activeTab = '#' + TAB_SWAP_BUTTON_PREFIX + target;

      $('.island-tabwrap').addClass('is-hidden');
      $('#TabSwapNav a').removeClass('is-active');
      $(toShow).removeClass('is-hidden');
      $(activeTab).addClass('is-active');
    }
  },
  handlers: {
    clickTabSwapNav: function handleClickTabSwapNav(event) {
      const current = getHashContent();
      const target = getAllAfterIndex(event.currentTarget.id, '_');

      event.preventDefault();

      this.tabs.switch(target);
    }
  },
  init: function initMenu() {
    const $tabWrap = $('.island-tabwrap');

    if ($tabWrap.length !== 0) {
      this.tabs.init($tabWrap);

      $(document).on('click', '#TabSwapNav a', this.handlers.clickTabSwapNav.bind(this));
    }
  }
};

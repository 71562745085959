export const testField = {
  setTabIndexes: function setTabIndexes() {
    $(".sample-test input:not([readonly]), .sample-test select").each(function (
        index,
        elem
    ) {
      $(elem).attr("tabindex", index + 1);
    });
  },
  init: function initTestField() {
    this.setTabIndexes();
    $(".sample-test-container").on(
        "focus",
        ".test-field input[readonly]",
        function () {
          $(this).blur();
        }
    );
  }
};

import './vendor/jquery';
import 'jquery-ujs';
import './vendor/bloodhound';
import './vendor/typeahead';
import 'chosen-npm/public/chosen.jquery';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker';
import './vendor/datatables'
import Handlebars from 'handlebars';
import Chart from 'chart.js';
import moment from 'moment';
import ko from 'knockout';
import * as modules from './modules';
import * as utils from './utils';
import * as classes from './classes';
import {components, plugins} from '@components';

Handlebars.registerHelper('equal', function (lvalue, rvalue, options) {
  if (arguments.length < 3) throw new Error('Handlebars Helper `equal` needs 2 parameters');
  if (lvalue !== rvalue) {
    return options.inverse(this);
  } else {
    return options.fn(this);
  }
});

ko.bindingHandlers.chosen = {
  init: function (element) {
    ko.bindingHandlers.options.init(element);
    $(element).chosen({disable_search_threshold: 4});
  },
  update: function (element, valueAccessor, allBindings) {
    ko.bindingHandlers.options.update(element, valueAccessor, allBindings);
    $(element).trigger('chosen:updated');
  }
};

// these need to be set globally for UJS and inline scripts to see them
window.Handlebars = Handlebars;
window.ko = ko;
window.moment = moment;
window.Chart = Chart;

function init(container) {
  container = container || 'body';

  const objects = {...modules, ...components, ...plugins};

  for (let [_, object] of Object.entries(objects)) {
    if (Object.hasOwn(object, 'init')) {
      object.init(container);
    }
  }
}

let LabPortal = {...modules, plugins, components, classes, utils, init};

$(document).ready(() => {
  $.rails.allowAction = function (link) {
    if (!link.attr('data-confirm')) {
      return true;
    }

    $.rails.showConfirmDialog(link);
    return false;
  };

  $.rails.confirmed = function (link) {
    link.removeAttr('data-confirm');
    return link.trigger('click.rails');
  };

  $.rails.showConfirmDialog = function (link) {
    components.modals.createModal('confirm', link.attr('data-confirm'));

    return $('#Btn_ConfirmAction').on('click', function () {
      return $.rails.confirmed(link);
    });
  };

  window.LabPortal = LabPortal;
  LabPortal.init();
});

export default LabPortal;

import $ from 'jquery';

export const addresses = {
  withPrefix: function (id, prefix) {
    return `#${prefix}${id}`;
  },
  getTargets: function ($container, prefix) {
    return [
      'address_countries',
      'address_region_states_span',
      'address_region_text',
      'address_region_label',
      'address_postal_label'
    ].reduce(
      function (final, key) {
        final[key] = $container.find(this.withPrefix(key, prefix));
        return final;
      }.bind(this),
      {}
    );
  },
  regionDisplay: function ($container, prefix) {
    if (prefix === null) {
      prefix = '';
    }

    const labels = {
      US: {
        region: 'State',
        postal: 'ZIP Code'
      },
      nonUS: {
        region: 'Region',
        postal: 'Postal Code'
      }
    };

    const $targets = this.getTargets($container, prefix);
    const isUS = $targets.address_countries.val() === 'US';

    $targets.address_region_states_span.toggle(isUS);
    $targets.address_region_text.toggle(!isUS).attr('disabled', isUS);
    $targets.address_region_label.html(isUS ? labels.US.region : labels.nonUS.region);
    $targets.address_postal_label.html(isUS ? labels.US.postal : labels.nonUS.postal);
  },
  addressRegionDisplay: function ($container, prefix) {
    if (prefix === void 0) {
      prefix = '';
    }

    this.regionDisplay($container, prefix);

    $container.find(`#${prefix}address_countries`).change(
      function () {
        this.regionDisplay($container, prefix);
      }.bind(this)
    );
  },
  init: function (container) {
    const $container = $(container || 'body');

    if ($container.find('#address_countries').length) {
      this.addressRegionDisplay($container);
    }

    if ($container.find('#billing_address_countries').length) {
      this.addressRegionDisplay($container, 'billing_');
    }
  }
};

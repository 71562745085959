import $ from 'jquery';

export const alert = {
  init: function initAlert() {
    const $alertWrapper = $('.l-alert-wrapper');

    if ($alertWrapper.length > 0) {
      this.hide($alertWrapper);
    }
  },
  create: function createAlert(message, type, wrapper) {
    const alert = this._makeAlert(message, type);
    const $alertWrapper = $(wrapper || '.l-alert-wrapper');

    $alertWrapper.append(alert, wrapper);
    this.hide($alertWrapper);
  },
  hide: function hideAlert($alertWrapper) {
    setTimeout(function () {
      $alertWrapper.find('.alert').slideUp(500, function () {
        $(this).remove();
      });
    }, 3000);
  },
  _makeAlert: function makeAlert(message, type) {
    return `<div class="alert alert-${type} alert-dismissible fade show" role="alert">
          <div class="alert-icon">
            <i class="absicon-alert-${type}"></i>
          </div>
          <div class="alert-message">
            ${message}
          </div>
        </div>`;
  }
};

export const sampleTest = {
  init: function initSampleTest() {
    $(document).ready(function () {
      const metaLocked = $('meta[title="sample_locked"]').attr('content');

      if (metaLocked === 'true') {
        $('.test-field-input').each(function (index) {
          if ($(this).find('select').length > 0) {
          } else {
            $(this).find('.input, input').attr('readonly', 'readonly');
            $(this).find('input').attr('disabled', 'disabled');
          }
        });
      }
    });

    $('#pycnometer_weight, #pycnometer_weight_with_water').on('change', function () {
      const weight = $('#pycnometer_weight').val();
      const weight_with_water = $('#pycnometer_weight_with_water').val();

      let water_weight = weight_with_water - weight;
      water_weight = Math.round(water_weight * 10000) / 10000;

      $('#pycnometer_water_weight').val(water_weight);
    });

    $(document).on('change', '.sample-test input, .sample-test select.equipment', function () {
      let testData, inputElems, sampleTest, testToModify, updatePath, equipmentId;

      const input = this;
      const $input = $(input);

      if ($input.hasClass('inactive')) {
        return false;
      }

      sampleTest = $input.parents('.sample-test');
      inputElems = sampleTest.find('input[data-field]');
      testToModify = sampleTest.data('test-to-modify');
      updatePath = sampleTest.attr('data-update-path');

      equipmentId = sampleTest.find('select.equipment').val();

      testData = {
        fields: {},
        equipment_id: equipmentId
      };

      if (input.type === 'checkbox') {
        testData.fields[$input.attr('data-field')] = $input.prop('checked') ? 1 : 0;
      } else {
        testData.fields[$input.attr('data-field')] = $input.val();
      }

      inputElems.each(function () {
        const $field = $(this);

        if ($field.prop('readonly')) {
          testData.fields[$field.attr('data-field')] = $field.val();
        }
      });

      if (equipmentId) {
        testData.fields.equipment_id = equipmentId;
      }

      $.ajax({
        type: 'PUT',
        dataType: 'json',
        url: updatePath,
        data: testData
      }).done(function (data) {
        sampleTest.attr('data-pass', data.pass);
        sampleTest.attr('data-pass-2', data.pass_2);

        if ($input.is('.equipment')) {
          $input.val(data.equipment_id);
        } else {
          $input.val(data.fields[$input.attr('data-field')]);
        }

        inputElems.filter('[readonly]').each(function (index, elem) {
          let fieldName;
          fieldName = $(this).attr('data-field');
          $(elem).val(data.fields[fieldName]);
        });

        if (testToModify && testToModify.length) {
          testToModify = '#' + testToModify;

          $(testToModify).addClass('is-disabled');

          $.get(window.location.href).done(function (response) {
            const updatedTest = $(response).find(testToModify).children();

            $(testToModify).empty().prepend(updatedTest);
            $(testToModify).removeClass('is-disabled');
          });
        }
      });
    });
  }
};

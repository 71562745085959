import $ from 'jquery';
import {formattedPrice} from '@utils';
import _ from 'lodash-es';

const services = {
  get: function (path) {
    return $.get(path);
  }
}

const view = (function () {
  const templateTargets = {
    testSelect: 'div#tpl_quotes_testSelect',
    contactInfo: 'div#tpl_quotes_contactInfo',
    summary: 'div#tpl_quotes_summary'
  };

  const templates = {};

  const methods = {
    /**
     * Find all select elements and initialize with the Chosen library
     * @return {void}
     */
    reinitChosen: function reinitChosen() {
      $('select').each(function (index, element) {
        $(element).chosen({width: '100%', disable_search_threshold: 4});
      });
    }
  };

  const update = {
    /**
     * Update quotes/_test_select.handlebars
     * @return {void}
     */
    testSelect: function updateTestSelectTemplate(data) {
      templateTargets.testSelect.html(
          templates.testSelect({tests: data.tests, options: data.options})
      );

      methods.reinitChosen();
    },
    /**
     * Update quotes/_summary.handlebars
     * @param  {object} quoteSummary data describing the quote
     * @param  {number} subtotal the sum of all prices in quoteSummary
     * @return {void}
     */
    summary: function updateSummaryTemplate(quoteSummary, subtotal) {
      templateTargets.summary.html(
          templates.summary({quoteSummary: quoteSummary, subtotal: subtotal})
      );
    },
    /**
     * Update quotes/_contact_info.handlebars
     * @param  {object} info data describing the contact info
     * @param  {number} subtotal the sum of all prices in quoteSummary
     * @return {void}
     */
    contactInfo: function updateContactInfoTemplate(info) {
      templateTargets.contactInfo.html(
          templates.contactInfo({
            contact_id: info.contact_id,
            company_name: info.company_name,
            contact_name: info.contact_name,
            address: info.address
          })
      );
    }
  };

  function init() {
    for (let key in templateTargets) {
      if (templateTargets.hasOwnProperty(key)) {
        const selector = 'script#tpl_quotes_' + key + '_template';
        const templateWrapper = $(selector);

        templateTargets[key] = $(templateTargets[key]);
        if (templateWrapper.length > 0) {
          templates[key] = Handlebars.compile(templateWrapper.html());
        }
      }
    }
  }

  return {
    init: init,
    methods: methods,
    update: update
  };
})();


const controller = (function () {
      const SELECTORS = {
        form: 'form#quotes_form',
        formWrap: 'div#quotes_formWrap',
        isMember: 'input#quotes_isMember',
        quotePath: 'input#quotes_quotePath',
        quoteLinePath: 'input#quotes_quoteLinePath',
        quoteLineOption: 'input#quotes_quoteLineOptions',
        testHeader: 'div[data-test-header]',
        testSelectWrap: 'div#tpl_quotes_testSelect',
        testSelect: 'select[data-test-selection]',
        testQuantity: 'input[data-test-selection-quantity]',
        testDelete: 'a[data-delete-test-selection]',
        addItem: 'button#quotes_addItem',
        discountValue: 'input#quotes_discountValue',
        expandAllRows: 'a#quotes_expandAllRows',
        collapseAllRows: 'a#quotes_collapseAllRows',
        contactInfo: 'input#data_contactInfo'
      };

      let tests = [];
      let testPrices = [];
      let testOptions = [];
      let quoteSummary = [];

      const jq = {};

      const methods = {
        tests: {
          addItem: function addItemToTests() {
            tests.push({
              title: null,
              subtitle: null,
              price: null,
              basePrice: null,
              test_or_type_id: 'NEW-' + tests.length,
              quantity: null,
              selectionPath: null,
              isOpen: true,
              destroy: false
            });
          },
          updateItem: function updateItemInTests(selectionId, data) {
            const test = _.chain(tests)
                .filter(function (test) {
                  return test.test_or_type_id === selectionId;
                })
                .last()
                .value();

            for (let key in data) {
              if (data.hasOwnProperty(key)) {
                test[key] = data[key];
              }
            }
          },
          removeItem: function removeItemFromTests(selectionId) {
            const test = _.chain(tests)
                .filter(function (test) {
                  return test.test_or_type_id === selectionId;
                })
                .last()
                .value();

            test.destroy = true;
          },
          formatData: function formatTestDataForTests(option) {
            const _option = $(option);
            let priceType;

            if (jq.isMember.val() === 'true') {
              priceType = 'member_price';
            } else {
              priceType = 'price';
            }

            const price = parseFloat(
                _.result(_.find(testPrices, {id: option.val()}), priceType),
                10
            );

            return {
              title: option.text(),
              subtitle: `Unit Price: ${formattedPrice(price)}`,
              price: price,
              basePrice: price,
              test_or_type_id: option.val(),
              selectionPath: {
                type: option.data('type'),
                group: option.data('group'),
                name: option.text()
              }
            };
          },
          calculateDiscount: function calculateDiscountForTests(discount) {
            let i;
            let newPrice;

            discount = parseInt(discount, 10) / 100;

            if (discount > 1 || discount < 0) {
              jq.discountValue.val('');
              return false;
            }

            for (i = 0; i < tests.length; i++) {
              newPrice = tests[i].basePrice - tests[i].basePrice * discount;
              tests[i].subtitle = `Unit Price: ${formattedPrice(newPrice)}`;
              tests[i].price = newPrice;
            }
          },
          updateOptions: function updateOptions(prevOption) {
            let a, b, c, t;

            const options = testOptions;

            // FIXME: not very elegant but hopefully gets the job done
            for (a = 0; a < options.length; a++) {
              for (b = 0; b < options[a].test_group.length; b++) {
                for (c = 0; c < options[a].test_group[b].tests.length; c++) {
                  for (t = 0; t < tests.length; t++) {
                    if (options[a].test_group[b].tests[c].value === tests[t].test_or_type_id) {
                      options[a].test_group[b].tests[c].inactive = !tests[t].destroy;
                    } else {
                      if (prevOption && prevOption === options[a].test_group[b].tests[c].value) {
                        options[a].test_group[b].tests[c].inactive = '';
                      } else {
                        options[a].test_group[b].tests[c].inactive =
                            options[a].test_group[b].tests[c].inactive;
                      }
                    }
                  }
                }
              }
            }

            testOptions = options;
            methods.testSelectView.update();
          },
          prepareForSubmit: function prepareForSubmit() {
          }
        },
        quoteSummary: {
          update: function updateQuoteSummary() {
            let subtotal = 0;
            let price = 0;
            let quantity = 0;

            for (let i = 0; i < tests.length; i++) {
              if (!tests[i].destroy) {
                price = parseFloat(tests[i].price, 10);
                quantity = parseInt(tests[i].quantity, 10);

                if (isNaN(price) || isNaN(quantity)) {
                  subtotal = subtotal;
                } else {
                  subtotal = subtotal + price * quantity;
                }
              }
            }

            quoteSummary = methods.quoteSummary.build();
            view.update.summary(quoteSummary, formattedPrice(subtotal, '$'));
          },
          build: function buildQuoteSummary() {
            let summary = [];
            const subtotal = null;
            let path = null;

            let typeName = null;
            let groupName = null;
            let testName = null;
            let testData = null;

            let typeIndex = null;
            let groupIndex = null;
            let testIndex = null;

            for (let i = 0; i < tests.length; i++) {
              if (!tests[i].destroy) {
                path = tests[i].selectionPath;

                if (path) {
                  typeName = {
                    h: path.type,
                    s: _.snakeCase(path.type)
                  };

                  groupName = {
                    h: path.group,
                    s: _.snakeCase(path.group)
                  };

                  testName = {
                    h: path.name,
                    s: _.snakeCase(path.name)
                  };

                  testData = {
                    name: testName.h,
                    slug: testName.s,
                    quantity: tests[i].quantity,
                    price: tests[i].price
                  };

                  if (tests[i].quantity) {
                    if (isNaN(tests[i].price) || isNaN(tests[i].quantity)) {
                      testData.formattedPrice = '';
                    } else {
                      testData.formattedPrice = '$' + (tests[i].quantity * tests[i].price).toFixed(2);
                    }
                  }

                  // FIXME: look for a way to accomplish this recursively
                  typeIndex = _.findIndex(summary, function (chr) {
                    return chr.slug == typeName.s;
                  });

                  if (typeIndex === -1) {
                    summary.push({
                      name: typeName.h,
                      slug: typeName.s,
                      groups: []
                    });

                    // select the last item inserted
                    typeIndex = summary.length - 1;
                  }

                  groupIndex = _.findIndex(summary[typeIndex].groups, function (chr) {
                    return chr.slug == groupName.s;
                  });

                  if (groupIndex === -1) {
                    summary[typeIndex].groups.push({
                      name: groupName.h,
                      slug: groupName.s,
                      tests: []
                    });

                    groupIndex = summary[typeIndex].groups.length - 1;
                  }

                  testIndex = _.findIndex(summary[typeIndex].groups[groupIndex].tests, function (chr) {
                    return chr.slug == testName.s;
                  });

                  if (testIndex === -1) {
                    summary[typeIndex].groups[groupIndex].tests.push(testData);
                    testIndex = summary[typeIndex].groups[groupIndex].tests.length - 1;
                  } else {
                    summary[typeIndex].groups[groupIndex].tests[testIndex] = testData;
                  }
                }
              }
            }

            summary = _.sortBy(summary, function (n) {
              return n.name;
            });

            return summary;
          }
        },
        testSelectView: {
          update: function updateTestSelectView() {
            if (jq.discountValue.val()) {
              methods.tests.calculateDiscount(jq.discountValue.val());
            }

            view.update.testSelect({
              tests: tests,
              options: testOptions
            });
          }
        }
      };

      const events = {
        /**
         * Listens for a `click` event on 'button#quotes_addItem'.
         * Creates a new object in `tests` and a new test selection
         * item in the view.
         */
        addNewItem: function addNewItemEvent() {
          jq.addItem.on('click', function createNewItem(event) {
            event.preventDefault();
            methods.tests.addItem();
            methods.testSelectView.update();
            methods.quoteSummary.update();
          });
        },
        /**
         * Listens for a `click` event on `a[data-delete-selection]`.
         * Removes the selected test from the view and from `tests`
         */
        deleteItem: function deleteItemEvent() {
          jq.testSelectWrap.on('click', SELECTORS.testDelete, function deleteItem(event) {
            const selectionId = $(this).data('delete-test-selection');

            event.preventDefault();
            methods.tests.removeItem(selectionId);
            methods.tests.updateOptions(selectionId);
            methods.testSelectView.update();
            methods.quoteSummary.update();
          });
        },
        /**
         * Listens for a `change` event on `select#quotes_testSelect`.
         * Gets data from the selected option and updates `tests` array.
         */
        selectItem: function selectItemEvent() {
          let prevOption;

          jq.testSelectWrap
              .on('chosen:showing_dropdown', SELECTORS.testSelect, function cacheCurrent() {
                prevOption = this.value;
              })
              .on('change', SELECTORS.testSelect, function selectItem(event) {
                const _this = $(this);
                const selectionId = _this.data('test-selection');
                const option = _this.find('option').filter(':selected');

                methods.tests.updateItem(selectionId, methods.tests.formatData(option));
                methods.tests.updateOptions(prevOption);
                methods.testSelectView.update(prevOption);
                methods.quoteSummary.update();
              });
        },
        /**
         * Listens for a `change` event in `input[data-test-selection-quantity]`.
         * Gets value of input and updates `tests` array, then view.
         */
        changeQuantity: function changeQuantityEvent() {
          jq.testSelectWrap.on('change', SELECTORS.testQuantity, function changeQuantity(event) {
            const _this = $(this);
            const selectionId = _this.data('test-selection-quantity');

            const data = {
              quantity: parseInt(_this.val(), 10)
            };

            methods.tests.updateItem(selectionId, data);
            methods.testSelectView.update();
            methods.quoteSummary.update();
          });
        },
        /**
         * Listens for a `click` event on `a#quotes_expandAllRows`.
         * Flags all tests as visible and updates view.
         */
        expandSelectRows: function expandSelectRowsEvent() {
          jq.expandAllRows.on('click', function expandSelectRows(event) {
            event.preventDefault();

            for (let i = 0; i < tests.length; i++) {
              tests[i].isOpen = true;
            }

            methods.testSelectView.update();
          });
        },
        /**
         * Listens for a `click` event on `a#quotes_collapseAllRows`.
         * Flags all tests as hidden and updates view.
         */
        collapseSelectRows: function collapseSelectRowsEvent() {
          jq.collapseAllRows.on('click', function collapseSelectRows(event) {
            event.preventDefault();

            for (let i = 0; i < tests.length; i++) {
              tests[i].isOpen = false;
            }

            methods.testSelectView.update();
          });
        },
        /**
         * Listens for a `click` event on `div[data-test-header]
         */
        setSelectRowVisibility: function setSelectRowVisibilityEvent() {
          jq.testSelectWrap.on('click', SELECTORS.testHeader, function setSelectRowVisibility(event) {
            const parent = $(this).parent('div.foldup');

            for (let i = 0; i < tests.length; i++) {
              if (tests[i].test_or_type_id === parent.data('test-selection')) {
                tests[i].isOpen = !tests[i].isOpen;
              }
            }

            methods.testSelectView.update();
          });
        },
        /**
         * Listens for a `blur` event on `input@quotesDiscountValue`.
         * Capture the value and calculate an appropriate discount.
         */
        applyDiscount: function applyDiscountEvent() {
          jq.discountValue.on('blur', function applyDiscount() {
            const _this = $(this);
            methods.tests.calculateDiscount(_this.val());
            methods.testSelectView.update();
            methods.quoteSummary.update();
          });
        },
        /**
         * Listens for a `submit` event on `form#quotes_header`.
         * Processes the form before handling submit.
         */
        submitForm: function submitFormEvent() {
          jq.form.on('submit', function processForm(event) {
            const _this = $(this);
            event.preventDefault();

            // find each selected option and remove the `disabled`
            // attribute so the value is properly submitted
            jq.testSelectWrap.find(SELECTORS.testSelect).each(function (index) {
              $(this).find('option').filter(':selected').removeAttr('disabled');
            });

            this.submit();
          });
        }
      };

      /**
       * Loop over all keys within the `events` object,
       * and execute a function named after each to initiate
       * that event.
       */
      function initEvents() {
        for (let key in events) {
          if (events.hasOwnProperty(key)) {
            events[key]();
          }
        }
      }

      function init() {
        for (let key in SELECTORS) {
          if (SELECTORS.hasOwnProperty(key)) {
            jq[key] = $(SELECTORS[key]);
          }
        }

        view.init();
        initEvents();

        view.update.contactInfo(JSON.parse(jq.contactInfo.val()));
        services
            .get(jq.quotePath.val())
            .done(function processData(data) {
              tests = data.quoteLines;
              testOptions = data.options;
              testPrices = data.prices;

              methods.testSelectView.update();
              methods.quoteSummary.update();
              jq.formWrap.removeClass('is-hidden');
            })
            .fail(function handleError(jqXHR, textStatus, errorThrown) {
            });
      }

      return {
        init: init
      };
    }
)();


export const quotes = {
  services, controller, view
};
